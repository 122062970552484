import { hasValue } from "@xxl/common-utils";
import { useStateValue } from "cotton-box-react";
import type { FunctionComponent, PropsWithChildren } from "react";
import React, { useMemo } from "react";
import { useApiClients } from "../../../contexts/ApiClients";
import { useSessionSource } from "../../../contexts/Session";
import { useSharedData } from "../../../contexts/SharedData";
import type { Trackers } from "../../../contexts/Tracking";
import { TrackingContext } from "../../../contexts/Tracking";
import {
  DeliveryStream,
  getCustomerDetails,
} from "../../../utils/DeliveryStream";
import type {
  TrackAddToCartProps,
  TrackCheckoutProp,
  TrackFavoriteProductProps,
  TrackOrderConfirmationData,
  TrackProductClickProps,
  TrackProductViewProps,
} from "../../../utils/Tracking";
import {
  GoogleAnalytics,
  mapOrderConfirmationTrackingDataForElevate,
} from "../../../utils/Tracking";
import { Elevate } from "../../../utils/Tracking/elevate";
import {
  getConfirmationLocalStorage,
  getEventStreamConfirmationEvent,
  getGTMOrderConfirmationEvent,
  shouldSendEvent,
  updateConfirmationLocalStorage,
} from "../../../utils/Tracking/get-order-confirmation-event";
import {
  convertSiteUidToHost,
  legacySiteUidToSiteUid,
} from "../../../utils/xxl-shared-data";

const TrackingContextProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { configuration, pageType, siteCurrency, siteUid } =
    useSharedData().data;
  const { deliveryStreamApi, elevateApi } = useApiClients();
  const isLoggedIn = useStateValue(useSessionSource);
  const siteHost = convertSiteUidToHost(legacySiteUidToSiteUid(siteUid));

  const deliveryStream = useMemo<DeliveryStream>(
    () =>
      new DeliveryStream({
        api: deliveryStreamApi,
        pageType,
        siteCurrency,
        siteUid: legacySiteUidToSiteUid(siteUid),
        getCustomerDetails: () =>
          getCustomerDetails(
            isLoggedIn,
            configuration.amplifyConfig.aws_appsync_graphqlEndpoint
          ),
      }),
    [
      deliveryStreamApi,
      pageType,
      siteCurrency,
      siteUid,
      isLoggedIn,
      configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
    ]
  );

  const analytics = useMemo<GoogleAnalytics>(
    () => new GoogleAnalytics({ pageType, siteCurrency, siteHost }),
    [pageType, siteCurrency, siteHost]
  );

  const elevate = useMemo(
    () => new Elevate({ elevateApi, siteHost }),
    [elevateApi, siteHost]
  );

  const contextValue: Trackers = {
    // event properties will be unified when server side tracking interface is defined
    sendAddToCartEvent: ({
      product,
      list,
      deliveryStreamProduct,
      ticket,
    }: TrackAddToCartProps) => {
      analytics.sendAddToCartEvent({ product, list });
      if (hasValue(deliveryStreamProduct)) {
        void deliveryStream.sendAddToCartEvent(deliveryStreamProduct);
      }
      if (hasValue(ticket)) {
        void elevate.sendAddToCartEvent({ ticket });
      }
    },

    sendAddMultipackBundleEvent: analytics.sendAddMultipackBundleEvent,
    sendAddServiceToCartEvent: analytics.sendAddServiceToCartEvent,
    sendAddToFavoritesEvent: (props: TrackFavoriteProductProps) => {
      analytics.sendAddToFavoritesEvent(props);
      void elevate.sendFavoritesEvent("add")(props);
    },
    sendRemoveFromFavoritesEvent: (props: TrackFavoriteProductProps) => {
      analytics.sendRemoveFromFavoritesEvent(props);
      void elevate.sendFavoritesEvent("remove")(props);
    },
    sendFavoritesConfirmationClickEvent:
      analytics.sendFavoritesConfirmationClickEvent,
    sendSharedFavoritesLinkGetEvent: analytics.sendSharedFavoritesLinkGetEvent,
    sendProductImpressionsEvent: analytics.sendProductImpressionsEvent,
    sendCrossSalesCloseEvent: analytics.sendCrossSalesCloseEvent,
    sendCrossSalesCheckoutEvent: analytics.sendCrossSalesCheckoutEvent,
    sendAddOnContinueShoppingEvent: analytics.sendAddOnContinueShoppingEvent,
    sendAddOnEditCartEvent: analytics.sendAddOnEditCartEvent,
    sendAddOnCloseAccessoryCartEvent:
      analytics.sendAddOnCloseAccessoryCartEvent,
    sendClickGoToCheckoutEvent: analytics.sendClickGoToCheckoutEvent,
    sendCloseMiniCartEvent: analytics.sendCloseMiniCartEvent,
    sendOpenMiniCartEvent: (): void => {
      void deliveryStream.sendViewEvent();
    },
    sendReadMoreServicePDPClickEvent:
      analytics.sendReadMoreServicePDPClickEvent,
    sendReadMoreServiceCartClickEvent:
      analytics.sendReadMoreServiceCartClickEvent,
    sendOpenServicesPDPEvent: analytics.sendOpenServicesPDPEvent,
    sendProductClickInCartEvent: analytics.sendProductClickInCartEvent,
    sendRemoveFromCartEvent: analytics.sendRemoveFromCartEvent,
    sendRemoveServiceOnCartEvent: analytics.sendRemoveServiceOnCartEvent,
    sendRemoveServiceOnPDPEvent: analytics.sendRemoveServiceOnPDPEvent,
    sendSignupSuccessEvent: analytics.sendSignupSuccessEvent,
    sendSignupErrorEvent: analytics.sendSignupErrorEvent,
    sendSelectFilterEvent: analytics.sendSelectFilterEvent,
    sendSearchPageViewEvent: analytics.sendSearchPageViewEvent,
    sendCampaignPageViewEvent: analytics.sendCampaignPageViewEvent,
    sendSortChangeEvent: analytics.sendSortChangeEvent,
    sendProductClickEvent: ({ ticket, ...props }: TrackProductClickProps) => {
      analytics.sendProductClickEvent(props);
      if (hasValue(ticket)) {
        void elevate.sendClickEvent({ ticket });
      }
    },
    sendExchangeOrderEvent: analytics.sendExchangeOrderEvent,
    sendClickEvent: ({ ticket }) => {
      if (hasValue(ticket)) {
        void elevate.sendClickEvent({ ticket });
      }
    },

    // event properties will be unified when server side tracking interface is defined
    sendCheckoutEvent: ({
      products,
      step,
      deliveryStreamCheckout,
    }: TrackCheckoutProp): void => {
      analytics.sendCheckoutEvent({ products, step });
      void deliveryStream.sendViewEventForCheckout(deliveryStreamCheckout);
    },

    sendOrderConfirmationEvent: (
      orderConfirmationData: TrackOrderConfirmationData,
      shouldSendElevateEvent = true
    ): void => {
      const localStorageTrackingData = getConfirmationLocalStorage();
      if (
        !shouldSendEvent(
          localStorageTrackingData,
          orderConfirmationData.checkoutId
        )
      ) {
        return;
      }
      void getGTMOrderConfirmationEvent(orderConfirmationData).then(
        (gtmData) => {
          analytics.sendPurchaseOrderEvent(gtmData);
          void deliveryStream.sendOrderConfirmationEvent(
            getEventStreamConfirmationEvent(orderConfirmationData)
          );
          if (shouldSendElevateEvent) {
            void elevate.sendOrderConfirmationEvent(
              mapOrderConfirmationTrackingDataForElevate(
                orderConfirmationData.items
              )
            );
          }
          updateConfirmationLocalStorage(
            localStorageTrackingData,
            orderConfirmationData.checkoutId
          );
          return;
        }
      );
    },

    sendProductViewEvent: (props: TrackProductViewProps): void => {
      analytics.sendProductViewEvent(props);
      void deliveryStream.sendClickEvent({ id: props.product.variant });
    },
  };

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  );
};

export { TrackingContextProvider };
