import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { BadgesData, CampaignBadgeData } from "@xxl/product-search-api";
import { color } from "@xxl/theme";
import type * as CSSType from "csstype";
import { xxlTheme } from "../../styles/xxl-theme";
import type { TranslationKey } from "../../translations";
import type { BadgeType } from "..//data-types";

export type BadgeConfig = {
  backgroundColour: CSSType.Property.BackgroundColor;
  foregroundColour: CSSType.Property.Color;
  name: TranslationKey;
};
const { colors } = xxlTheme;
const automatedBadgesConfig: { [key in BadgeType]: BadgeConfig } = {
  ALMOST_SOLD_OUT: {
    backgroundColour: colors.xxlAmber,
    foregroundColour: colors.xxlBlack,
    name: "badge.almost.sold.out",
  },
  BEST_SELLER: {
    backgroundColour: colors.xxlTurquoise,
    foregroundColour: colors.xxlBlack,
    name: "badge.best.seller",
  },
  NEW: {
    backgroundColour: colors.xxlAmber,
    foregroundColour: colors.xxlBlack,
    name: "badge.new",
  },
  ONLINE: {
    backgroundColour: colors.xxlBlack,
    foregroundColour: colors.xxlWhite,
    name: "product.available.online",
  },
  POPULAR: {
    backgroundColour: colors.xxlTurquoise,
    foregroundColour: colors.xxlBlack,
    name: "badge.popular",
  },
  REWARD: {
    backgroundColour: colors.xxlGreen,
    foregroundColour: colors.xxlBlack,
    name: "badge.reward",
  },
  STORE: {
    backgroundColour: colors.xxlWhite,
    foregroundColour: colors.xxlBlack,
    name: "product.available.store",
  },
  XXL_RECOMMENDS: {
    backgroundColour: colors.xxlGreen,
    foregroundColour: colors.xxlBlack,
    name: "badge.xxl.recommends",
  },
  PRESALE: {
    backgroundColour: colors.xxlAmber,
    foregroundColour: colors.xxlBlack,
    name: "badge.presale",
  },
} as const;
export const getAutomatedBadgeConfig = (badgeType: BadgeType): BadgeConfig =>
  automatedBadgesConfig[badgeType];

const badgeTypeForAttribute: { [attribute: string]: BadgeType | undefined } = {
  "custom.automatedAlmostSoldOut": "ALMOST_SOLD_OUT",
  "custom.automatedBestSeller": "BEST_SELLER",
  "custom.automatedOnlyInStore": "STORE",
  "custom.automatedOnlyOnline": "ONLINE",
  "custom.automatedPopular": "POPULAR",
  "custom.automatedPresale": "PRESALE",
  "custom.automatedRecommends": "XXL_RECOMMENDS",
  "custom.automatedReward": "REWARD",
  ALMOST_SOLD_OUT: "ALMOST_SOLD_OUT",
  BEST_SELLER: "BEST_SELLER",
  NEW: "NEW",
  POPULAR: "POPULAR",
  PRESALE: "PRESALE",
  REWARD: "REWARD",
  XXL_RECOMMENDS: "XXL_RECOMMENDS",
};

const getBadgeTypeForAttribute = (attribute: string): BadgeType | null =>
  badgeTypeForAttribute[attribute] ?? null;

export const getAutomatedBadges = (
  badges: BadgesData | undefined
): BadgeType[] => {
  if (badges === undefined) {
    return [];
  }
  const automatedBadges = Object.values(badges)
    .flat()
    .filter(({ variant }) => variant === "LABEL")
    .map(({ attribute }) => getBadgeTypeForAttribute(attribute))
    .filter(isNotNullOrUndefined);
  return automatedBadges;
};

export const hexColorToName = (hexColor?: string) => {
  const fallback = "green";

  if (hexColor === undefined) {
    return fallback;
  }

  const hexColorFixed = hexColor.startsWith("##")
    ? hexColor.replace("#", "")
    : hexColor;

  const normalizedHex = hexColorFixed.toUpperCase();

  for (const key in color) {
    if (color[key as keyof typeof color].hex === normalizedHex) {
      return color[key as keyof typeof color].name;
    }
  }

  return fallback;
};

export const getCampaignHighlightedLabel = (
  badges: BadgesData | undefined
): CampaignBadgeData | null => {
  if (badges === undefined) {
    return null;
  }
  const campaignRibbon = Object.values(badges)
    .flat()
    .find(({ attribute }) => attribute === "custom.campaignMessage") as
    | CampaignBadgeData
    | undefined;
  return campaignRibbon ?? null;
};

export const getCampaignRibbon = (
  badges: BadgesData | undefined
): CampaignBadgeData | null => {
  if (badges === undefined) {
    return null;
  }
  const campaignRibbon = Object.values(badges)
    .flat()
    .find(({ attribute }) => attribute === "custom.campaignRibbon") as
    | CampaignBadgeData
    | undefined;
  const highlightedLabel = getCampaignHighlightedLabel(badges);

  if (campaignRibbon === undefined) {
    return null;
  }

  const fallbackColorName = hexColorToName(
    campaignRibbon.content?.backgroundColor
  );

  return {
    ...campaignRibbon,
    ...(campaignRibbon.content !== undefined && {
      content: {
        ...campaignRibbon.content,
        name: isNotNullOrUndefined(campaignRibbon.content.name)
          ? campaignRibbon.content.name
          : highlightedLabel?.content?.name ?? fallbackColorName,
      },
    }),
  };
};
