import { QuickShop } from "@/components/QuickShop/QuickShop";
import { type Theme, ThemeProvider } from "@mui/material/styles";
import { hasNoValue } from "@xxl/common-utils";
import { StatusCodes } from "http-status-codes";
import isEmpty from "lodash/isEmpty";
import type {
  AppContext,
  AppInitialProps,
  AppProps as NextAppProps,
} from "next/app";
import App from "next/app";
import { useEffect, useMemo, useState } from "react";
import { CartContextProvider } from "react-app/src/components/Cart/CartContextProvider";
import { ApiClientsProvider } from "react-app/src/components/ContextProviders/ApiClientsProvider";
import { TrackingContextProvider } from "react-app/src/components/ContextProviders/TrackingContextProvider";
import { TranslationsProvider } from "react-app/src/components/ContextProviders/TranslationsProvider";
import { SnackBarWrapper } from "react-app/src/components/SnackBar";
import type { EcomSiteUidLegacy, HeaderConfig } from "react-app/src/global";
import { Layout } from "../components/Layout/Layout";
import { EnvironmentDataProvider } from "../components/Providers/EnvironmentDataProvider";
import { SessionProvider } from "../components/Providers/SessionProvider";
import { Symplify } from "../components/Symplify/Symplify";
import type { XXLAppData } from "../global";
import "../styles/global.scss";
import type { DeviceType } from "../utils/app-page-helper";
import {
  getDeviceTypeFromHeaders,
  getMuiTheme,
} from "../utils/app-page-helper";
import { getPageType } from "../utils/common-page-data/common-page-data";
import {
  isTeamsales as checkIsTeamsales,
  getEnvVar,
} from "../utils/environment-variables";
import { initNextJsGiosg } from "../utils/giosg-init";
import { initGtm } from "../utils/gtm";
import { initKindlyChatbot } from "../utils/kindly-chatbot";
import {
  getLayoutProps,
  type LayoutProps,
} from "../utils/layout/with-layout-page-props";
import {
  getCachedLayoutProps,
  setCachedLayoutProps,
} from "../utils/server-side-cache/server-side-cache";
import HeadMetaTags from "./HeadMetaTags";
import { QueryClientProvider } from "@/components/Providers/QueryClientProvider";
import { log } from "@xxl/logging-utils";
import {
  getAccount,
  type UserAccountProps,
} from "react-app/src/components/UserDetails/UserAPI";
import { UserDataContext } from "react-app/src/contexts/UserData/UserDataContext";

const { NOT_FOUND } = StatusCodes;

const PAGE_TYPES_WITH_REWARDS_BANNER = [
  "checkout",
  "order-confirmation",
  "storeFinder",
  "guides",
  "campaignHub",
  "error",
  "service",
];

const PAGE_TYPES_WITH_LIMITED_HEADER = ["checkout"];

const PAGE_TYPES_WITH_HIDDEN_FOOTER_TOP_MARGIN = ["checkout", "brandIndex"];

if (process.env.NODE_ENV === "development") {
  log.error(
    "React is running in strict mode, please read more about different development behaviour here: https://react.dev/reference/react/StrictMode"
  );
}

const LOGOUT_PATHNAME = "logout";

export type InitialProps = {
  campaignHubUrl: string;
  deviceType: DeviceType;
  isTeamsales: boolean;
  layoutProps: LayoutProps;
};

type AppProps<P> = {
  pageProps?: P; // can be undefined when a page fails
} & InitialProps &
  Omit<NextAppProps<P>, "pageProps">;

const muiTheme = new Map<DeviceType, Theme>();

function getMuiThemeInternal(
  deviceType: DeviceType,
  param2: EcomSiteUidLegacy
): Theme {
  if (!muiTheme.has(deviceType)) {
    const theme = getMuiTheme(deviceType, param2); // Generate the theme only if it doesn't exist
    muiTheme.set(deviceType, theme); // Cache the theme for the deviceType
  }
  return muiTheme.get(deviceType) as Theme; // Return the cached theme
}

const XXLApp = ({
  Component,
  deviceType,
  pageProps,
  router,
  layoutProps,
  isTeamsales,
  campaignHubUrl,
}: AppProps<XXLAppData>) => {
  const isLogoutPage = router.pathname.includes(LOGOUT_PATHNAME);
  const {
    environmentData,
    giosg,
    logRocketApiId = "",
    serverGtmScriptUrl = "",
    statusCode,
    translations,
    specificKindlyChatApiKey,
  } = pageProps ?? {};
  const theme = getMuiThemeInternal(
    deviceType,
    environmentData?.siteUid ?? "xxl"
  );
  const { megaMenuContent, headerContent, infoMessage, footerContent } =
    layoutProps;
  const pageType = getPageType(router.pathname);
  const [showQuickShop, setShowQuickshop] = useState<boolean>(false);
  const shouldRenderSymplify = useMemo(
    () => environmentData?.symplifyId,
    [environmentData]
  );
  const [userData, setUserData] = useState<UserAccountProps | undefined>();

  const getUserData = async (graphqlUrl: string) => {
    try {
      const response = await getAccount(graphqlUrl);
      setUserData(response.data?.account ?? undefined);
    } catch (error) {
      log.error("Cannot fetch user data");
      log.debug("Error while fetching user data", error);
    }
  };

  /**
   * Make sure we user correct market and environment in local development
   */
  useEffect(() => {
    const { origin } = window.location;
    if (isEmpty(environmentData)) {
      return;
    }
    const { frontEndServerUrl } = environmentData;
    if (origin.includes("localhost") && frontEndServerUrl !== origin) {
      throw Error(
        `Please check url, seems that market and/or environment differ from parameters that server was started with.\n\nServer started with: ${frontEndServerUrl}`
      );
    }
  }, [environmentData]);

  useEffect(() => {
    if (
      pageType === "account" &&
      isEmpty(userData) &&
      environmentData?.configuration.amplifyConfig
        .aws_appsync_graphqlEndpoint !== undefined
    ) {
      void getUserData(
        environmentData.configuration.amplifyConfig.aws_appsync_graphqlEndpoint
      );
    }
  }, [pageType]);

  useEffect(() => {
    if (isEmpty(environmentData)) {
      return;
    }
    const {
      featureToggles: { toggle_kindly_chatbot, toggle_quick_shop },
      gtmId,
      kindlyChatbotKey,
      gitHash,
    } = environmentData;

    setShowQuickshop(toggle_quick_shop);

    //GTM
    initGtm(gtmId, serverGtmScriptUrl);

    // init LogRocket
    const isPreProdEnv = environmentData.frontEndServerUrl.includes("pre.");
    const shouldEnableLogRocket =
      logRocketApiId.trim() !== "" &&
      environmentData.featureToggles.toggle_log_rocket &&
      !isPreProdEnv &&
      hasNoValue(window.Cypress);

    if (shouldEnableLogRocket) {
      void import("../utils/third-party/logrocket").then(
        ({ default: initializeLogrocket }) => {
          initializeLogrocket({
            logRocketApiId,
            gitHash,
          });
          return;
        }
      );
    }

    // KINDLY CHATBOT INIT - Temporary mapping for soft launch.
    // nb router.pathname will have eg /customerservice and not the localised value since it's a middleware rewrite
    const whitelistedPaths = [
      "/account",
      "/checkout",
      "/customerservice",
      "/faq",
    ];

    const isLandingPage = document.location.pathname === "/";

    const isWhitelisted =
      specificKindlyChatApiKey !== undefined ||
      isLandingPage ||
      whitelistedPaths.some((p) => router.pathname.includes(p));

    if (toggle_kindly_chatbot && isWhitelisted && !isTeamsales) {
      initKindlyChatbot(specificKindlyChatApiKey ?? kindlyChatbotKey);
    } else if (giosg !== undefined) {
      const { giosgEnabled, giosgId } = giosg;
      if (giosgEnabled === true) {
        initNextJsGiosg(giosgId);
      }
    }
  }, [environmentData, giosg, isTeamsales, router.pathname]);

  if (isLogoutPage || statusCode === NOT_FOUND) {
    return (
      <>
        <HeadMetaTags />
        <Component {...pageProps} />
      </>
    );
  }
  const { headerLogo, headerLinks, campaignHubLink } = headerContent;
  const headerConfig: HeaderConfig = {
    logoUrl: headerLogo?.url ?? "",
    logoAlt: headerLogo?.alt ?? "",
    headerLinks: headerLinks ?? [],
    isTeamsales,
    campaignHubUrl,
    campaignHubLink: campaignHubLink,
    showHeaderLinks: !isTeamsales,
  };

  const shouldShowRewardsBanner =
    PAGE_TYPES_WITH_REWARDS_BANNER.includes(pageType);
  const shouldShowLimitedHeader =
    PAGE_TYPES_WITH_LIMITED_HEADER.includes(pageType);
  const shouldHideFooterTopMargin =
    PAGE_TYPES_WITH_HIDDEN_FOOTER_TOP_MARGIN.includes(pageType);

  return (
    <>
      <ThemeProvider theme={theme}>
        <EnvironmentDataProvider environmentData={environmentData}>
          <SessionProvider isReactApp={false}>
            <ApiClientsProvider>
              <TrackingContextProvider>
                <TranslationsProvider nextJsTranslations={translations}>
                  <UserDataContext.Provider value={userData}>
                    <QueryClientProvider>
                      <CartContextProvider isTeamsales={isTeamsales}>
                        <HeadMetaTags />
                        <SnackBarWrapper />
                        {showQuickShop && <QuickShop />}
                        <Layout
                          footerContent={footerContent}
                          megaMenuContent={megaMenuContent}
                          headerConfig={headerConfig}
                          infoMessage={infoMessage}
                          shouldShowLimitedHeader={shouldShowLimitedHeader}
                          shouldShowRewardsBanner={shouldShowRewardsBanner}
                          shouldHideFooterTopMargin={shouldHideFooterTopMargin}
                        >
                          <Component {...pageProps} />
                        </Layout>
                      </CartContextProvider>
                    </QueryClientProvider>
                  </UserDataContext.Provider>
                </TranslationsProvider>
              </TrackingContextProvider>
            </ApiClientsProvider>
          </SessionProvider>
        </EnvironmentDataProvider>
      </ThemeProvider>
      {environmentData !== undefined && shouldRenderSymplify !== undefined && (
        <Symplify id={environmentData.symplifyId} />
      )}
    </>
  );
};

XXLApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps & InitialProps> => {
  try {
    const { req } = context.ctx;
    const deviceType = getDeviceTypeFromHeaders(req?.headers);

    const [appContext, layoutProps] = await Promise.all([
      App.getInitialProps(context),
      (async () => {
        try {
          let cachedLayoutProps = await getCachedLayoutProps();
          if (cachedLayoutProps === null) {
            cachedLayoutProps = await getLayoutProps();
            await setCachedLayoutProps(cachedLayoutProps);
          }
          return cachedLayoutProps;
        } catch (error) {
          log.error("Error while getting layout props", error);
          throw new Error("Could not fetch layout props");
        }
      })(),
    ]);
    return {
      ...appContext,
      deviceType,
      layoutProps,
      isTeamsales: checkIsTeamsales(),
      campaignHubUrl: getEnvVar("REQUEST_MAPPING_CAMPAIGNHUBPAGE"),
    };
  } catch (error) {
    log.error(error);
    throw Error(
      "Could not create layout props. Please see NextJS console for more information. Possible causes could be expired token or Java code for Spring needs to be compiled (if server was started with --skip-build flag)."
    );
  }
};

export default XXLApp;
