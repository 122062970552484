import type { ProductCardDataV2 } from "react-app/src/utils/ProductData/product-card-data-helper";
import type { ServiceProductsProps } from "./types";

type EAN = string;

const sortItemsByEanOrder = (
  items: ProductCardDataV2[],
  eanOrder: EAN[]
): ProductCardDataV2[] => {
  const eanMap = new Map(eanOrder.map((ean, index) => [ean, index]));

  return items.sort((a, b) => {
    const indexA = eanMap.get(a.ean) ?? Infinity;
    const indexB = eanMap.get(b.ean) ?? Infinity;
    return indexA - indexB;
  });
};

export const additionalSalesToServiceProducts = (
  additionalSalesProducts: {
    accessoryProducts: ProductCardDataV2[];
    crossSalesProducts: ProductCardDataV2[];
    serviceProducts: ProductCardDataV2[];
  } | null,
  sortOrder: EAN[] // the returned products will be sorted by the eans in the array
): ServiceProductsProps["serviceProducts"] => {
  const { serviceProducts = [] } = additionalSalesProducts ?? {};

  return sortItemsByEanOrder(serviceProducts, sortOrder).map(
    ({ brandName, categoryBreadcrumbs, ean, name, price, summary }) => ({
      brandName,
      categoryCode: categoryBreadcrumbs.at(-1)?.code ?? "",
      description: summary ?? "",
      ean,
      name,
      priceData: price,
    })
  );
};
