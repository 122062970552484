import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { Text } from "@/react-components/Text/Text";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery/useXxlMediaQuery";
import type { ProductData } from "@xxl/product-search-api";
import {
  getOnlineStockStatus,
  getPreferredStores,
  getStoresWithStockCount,
} from "../helpers";
import { useStoresQuery } from "../queries/useStoresQuery";
import { StockLevels } from "../types";
import { OnlineAvailability } from "./OnlineAvailability/OnlineAvailability";
import {
  PosAvailability,
  PosAvailabilityInitialStockLevels,
  PosExcludedFromClickAndCollect,
} from "./PosAvailability/PosAvailability";

const { spaces } = xxlTheme;

type Props = {
  isOnlinePurchaseAllowed: boolean;
  product: ProductData;
  selectedVariantCode: string | null;
  toggleIsCnCDialogOpen: () => void;
};
export const StoreAvailability = ({
  isOnlinePurchaseAllowed,
  product,
  selectedVariantCode,
  toggleIsCnCDialogOpen,
}: Props) => {
  const { t } = useTranslations();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const hideCnc = [
    "MULTIPACK",
    "PRODUCT_MULTIPACK",
    "BUNDLE_MULTIPACK",
  ].includes(product.type);

  const selectedVariant =
    product.variants.find((variant) => variant.code === selectedVariantCode) ??
    null;
  const { data: storesData = [] } = useStoresQuery();
  const preferredStores = getPreferredStores(storesData);
  const storesWithStockCount = getStoresWithStockCount(
    selectedVariant?.availability
  );

  const computeOnlineStockStatus = () => {
    if (selectedVariant !== null) {
      return (
        getOnlineStockStatus(selectedVariant.availability) ??
        StockLevels.OUT_OF_STOCK
      );
    }
    return product.stockStatus;
  };

  const getStoreStockStatus = () => {
    const instock = product.variants.find((v) =>
      v.availability.find(
        (a) => a.channel === "STORE" && a.stockStatus === "INSTOCK"
      )
    );
    if (instock !== undefined) {
      return StockLevels.IN_STOCK;
    }
    const lowstock = product.variants.find((v) =>
      v.availability.find(
        (a) => a.channel === "STORE" && a.stockStatus === "LOWSTOCK"
      )
    );
    if (lowstock !== undefined) {
      return StockLevels.LOW_STOCK;
    }
    return StockLevels.OUT_OF_STOCK;
  };

  return (
    <>
      <XxlStack
        width={"100%"}
        gridTemplateColumns={"max-content 1fr"}
        display={!isMobile ? "grid" : "flex"}
        flexWrap={!isMobile ? "nowrap" : "wrap"}
        gap={spaces.smallRegular}
      >
        <XxlStack>
          <Text typography="baseBold">
            {t("product.details.clickcollect.stock.status.online")}:
          </Text>
          <OnlineAvailability
            stockStatus={computeOnlineStockStatus()}
            isLoading={false}
            isPurchaseAllowed={isOnlinePurchaseAllowed}
          />
        </XxlStack>
        {!hideCnc && (
          <XxlStack width={"fit-content"}>
            <Text typography="baseBold">
              {t("product.details.clickcollect.stock.status.cc")}:
            </Text>
            {product.isExcludedFromClickAndCollect === true && (
              <PosExcludedFromClickAndCollect />
            )}
            {selectedVariantCode === null &&
              product.isExcludedFromClickAndCollect === false && (
                <PosAvailabilityInitialStockLevels
                  initPosStockStatus={getStoreStockStatus()}
                  onBtnClick={toggleIsCnCDialogOpen}
                />
              )}
            {selectedVariantCode !== null &&
              product.isExcludedFromClickAndCollect === false && (
                <PosAvailability
                  isLoading={false}
                  onBtnClick={toggleIsCnCDialogOpen}
                  preferredStores={preferredStores}
                  stores={storesData}
                  storesWithStockCount={storesWithStockCount}
                  variantAvailabilityData={selectedVariant?.availability ?? []}
                />
              )}
          </XxlStack>
        )}
      </XxlStack>
    </>
  );
};
