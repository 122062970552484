import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  useCartContext,
  ENABLE_CART_API_REQUEST,
  UPDATE_CART_COUNT,
} from "./CartState";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { isNotEmpty } from "@xxl/common-utils";
import {
  MiniCartOverlay,
  MiniCartWrapper,
  MiniCartHeader,
  CloseButton,
  HeaderText,
  Footer,
  GoToCheckout,
  MiniCartTopWrapper,
  EmptyCartTopWrapper,
  EmptyCartWrapper,
  CloseIcon,
} from "./Styles/MiniCartContent.styled";
import {
  EmptyPageHeading,
  EmptyPageImage,
  BackButtonWrapper,
  BackButtonStyled,
} from "./Styles/CartContent.styled";
import { BackButton } from "./Components/BackButton";
import {
  PricesList,
  SmallPriceRow,
  TotalPriceRow,
} from "./Styles/TotalPrice.styled";
import { ProductsList } from "./Components/ProductsList";
import { XXLLoader } from "../XXLLoader";
import { CART_BASE_PATH } from "../../constants";
import { windowAccess } from "../../utils/Window";
import { MiniCartButton } from "./Components/MiniCartButton";
import { useTracking } from "../../contexts/Tracking";
import { setMiniCartCountClientSide } from "./cart-page-helper";
import { useSessionSource } from "../../contexts/Session";
import {
  addEventListener,
  removeEventListener,
} from "react-app/src/utils/xxl-event";
import { useStateValue } from "cotton-box-react";
import {
  getEmployeeDiscount,
  getRemainingDiscountTotal,
} from "./utils/discountPrice";
import { formatPrice } from "@xxl/price-formatting-utils";

type MiniCartProps = {
  isCartPage: boolean;
};

export const MiniCartContent: React.FunctionComponent<MiniCartProps> = ({
  isCartPage,
}) => {
  const { state, dispatch } = useCartContext();
  const tracking = useTracking();
  const isLoggedIn = useStateValue(useSessionSource);
  const [isMiniCartVisible, setIsMiniCartVisible] = useState(false);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [maxListHeight, setMaxListHeight] = useState(0);
  const [cartHasProducts, setCartHasProducts] = useState(false);
  const [cartHasRequestErrors, setCartHasRequestErrors] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const [stickyHeaderElement, setStickyHeaderElement] =
    useState<HTMLElement | null>(null);
  const [reactHeaderElement, setReactHeaderElement] =
    useState<HTMLElement | null>(null);
  const { t } = useTranslations();
  const { siteCurrency } = windowAccess()._sharedData;

  const reloadCart = useCallback(() => {
    dispatch({
      type: UPDATE_CART_COUNT,
    });
  }, [dispatch]);

  const openMiniCart = useCallback(() => {
    if (!isCartPage) {
      windowAccess().kindlyChat?.hideBubble();
      tracking.sendOpenMiniCartEvent();
      dispatch({
        type: ENABLE_CART_API_REQUEST,
      });
      stickyHeaderElement?.classList.toggle("header--fixed", true);
      reactHeaderElement?.classList.toggle("header--fixed", true);
      reloadCart();
      setIsMiniCartVisible(true);
      setIsOpen(true);
      setIsClosed(false);
      windowAccess().LockScreen.lock(true);
    }
  }, [
    dispatch,
    isCartPage,
    reactHeaderElement?.classList,
    reloadCart,
    stickyHeaderElement?.classList,
    tracking,
  ]);

  useEffect(() => {
    addEventListener("OPEN_MINI_CART", openMiniCart);
    return () => removeEventListener("OPEN_MINI_CART", openMiniCart);
  }, [openMiniCart]);

  const closeMiniCart = () => {
    windowAccess().kindlyChat?.showBubble();
    tracking.sendCloseMiniCartEvent();
    setIsOpen(false);
    setIsClosed(true);
    windowAccess().LockScreen.unlock();
    stickyHeaderElement?.classList.toggle("header--fixed", false);
    reactHeaderElement?.classList.toggle("header--fixed", false);
  };

  useEffect(() => {
    reloadCart();
  }, [isLoggedIn]);

  useEffect(() => {
    if (state.displayCart !== undefined) {
      setCartHasProducts(state.displayCart.items.length > 0);
      setCartHasRequestErrors(
        state.displayCart.errors !== undefined &&
          state.displayCart.errors.length > 0 &&
          state.displayCart.items.length === 0
      );
    }
  }, [state.displayCart]);

  useEffect(() => {
    const headerElement = headerRef.current;
    const footerElement = footerRef.current;
    const setElementsHeight = () => {
      if (headerElement !== null && headerElement.offsetHeight !== 0) {
        setHeaderHeight(headerElement.offsetHeight);
      }

      if (footerElement !== null && footerElement.offsetHeight !== 0) {
        setFooterHeight(footerElement.offsetHeight);
      }
    };

    setTimeout(() => {
      setElementsHeight();
    }, 200);
  }, [isMiniCartVisible, state.displayCart]);

  useEffect(() => {
    if (footerHeight !== 0 && headerHeight !== 0) {
      setMaxListHeight(window.innerHeight - headerHeight - footerHeight);
    }
  }, [footerHeight, headerHeight]);

  useEffect(() => setMiniCartCountClientSide(dispatch), [dispatch]);

  useEffect(() => {
    setStickyHeaderElement(document.getElementById("js-sticky-header"));
    setReactHeaderElement(document.getElementById("js-react-header"));
  }, []);

  useEffect(() => {
    const kindly = document.getElementById("kindy-chat-api");
    if (kindly === null) return;
    kindly.style.display = isOpen ? "none" : "block";

    return () => {
      kindly.style.display = "block";
    };
  }, [isOpen]);

  const employeeDiscount = getEmployeeDiscount(
    state.cart?.data?.cart?.totals.cartDiscountTotals ?? []
  );
  const shouldShowEmployeeDiscount =
    state.cart?.data?.cart?.hasDiscounts === true && employeeDiscount > 0;

  // remaining discounts are all discounts that are not employee discounts
  const remainingCartDiscount = getRemainingDiscountTotal(
    state.cart?.data?.cart?.totals.cartDiscountTotals ?? []
  );
  const shouldShowRemainingDiscount =
    state.cart?.data?.cart?.hasDiscounts === true && remainingCartDiscount > 0;

  return (
    <>
      {!isCartPage && (
        <MiniCartButton
          isLoading={state.isCartEditing}
          numberOfItemsInCart={state.miniCartCounter}
          onClick={openMiniCart}
        />
      )}
      {!isCartPage && isMiniCartVisible && (
        <MiniCartOverlay
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (event.target === event.currentTarget) {
              closeMiniCart();
            }
          }}
        >
          <MiniCartWrapper
            onAnimationEnd={() => {
              if (isClosed) {
                setIsMiniCartVisible(false);
              }
            }}
            isOpen={isOpen}
            isClosed={isClosed}
          >
            {cartHasProducts || state.miniCartCounter !== 0 ? (
              <>
                <MiniCartTopWrapper>
                  <MiniCartHeader ref={headerRef}>
                    <HeaderText>
                      {t("cart.header")}
                      &nbsp;(
                      {state.displayCart?.totalItems ?? state.miniCartCounter})
                    </HeaderText>
                    <CloseButton
                      aria-label={t("general.close")}
                      type="button"
                      onClick={closeMiniCart}
                    >
                      <CloseIcon />
                    </CloseButton>
                  </MiniCartHeader>
                  {cartHasProducts ? (
                    <ProductsList
                      isMiniCart={true}
                      miniCartListHeight={
                        maxListHeight > 0 ? maxListHeight : undefined
                      }
                    />
                  ) : (
                    <XXLLoader />
                  )}
                </MiniCartTopWrapper>
                <Footer ref={footerRef}>
                  <PricesList>
                    {state.displayCart?.priceWithoutDiscount !== undefined && (
                      <SmallPriceRow>
                        <span>{t("cart.summary.partial.sum")}</span>
                        <span>
                          {formatPrice(
                            state.displayCart.priceWithoutDiscount,
                            siteCurrency
                          )}
                        </span>
                      </SmallPriceRow>
                    )}
                    {shouldShowRemainingDiscount ? (
                      <SmallPriceRow discountPrice={true}>
                        <span>{t("cart.summary.discount")}</span>
                        <span>
                          -{formatPrice(remainingCartDiscount, siteCurrency)}
                        </span>
                      </SmallPriceRow>
                    ) : null}
                    {shouldShowEmployeeDiscount ? (
                      <SmallPriceRow employeePrice={true}>
                        <span>{t("cart.summary.employee.discount")}</span>
                        <span>
                          ~{formatPrice(employeeDiscount, siteCurrency)}
                        </span>
                      </SmallPriceRow>
                    ) : null}
                    {(state.displayCart?.isFreeDelivery === true ||
                      isNotEmpty(state.displayCart?.deliveryCost)) && (
                      <SmallPriceRow>
                        <span>{t("cart.summary.shipping.title")}</span>
                        <span>
                          {state.displayCart.isFreeDelivery === true
                            ? t("cart.summary.shipping.free")
                            : state.displayCart.deliveryCost}
                        </span>
                      </SmallPriceRow>
                    )}
                  </PricesList>
                  <TotalPriceRow>
                    <span>{t("cart.summary.footer.total.sum")}</span>
                    <span>{state.displayCart?.totalPrice}</span>
                  </TotalPriceRow>
                  <GoToCheckout
                    href={`${window.location.origin}${CART_BASE_PATH}`}
                    onClick={() => tracking.sendClickGoToCheckoutEvent()}
                    data-testid="go-to-checkout"
                    className="button button--accent button--large button--full-width"
                  >
                    {t("cart.go.to.checkout")}
                  </GoToCheckout>
                </Footer>
              </>
            ) : (
              <EmptyCartWrapper>
                <MiniCartHeader ref={headerRef}>
                  <HeaderText>
                    {t("cart.header")}
                    {!cartHasRequestErrors && <>&nbsp;(0)</>}
                  </HeaderText>
                  <CloseButton type="button" onClick={closeMiniCart}>
                    <CloseIcon />
                  </CloseButton>
                </MiniCartHeader>
                <EmptyCartTopWrapper>
                  <EmptyPageHeading data-testid="cart-empty-message">
                    {cartHasRequestErrors
                      ? t("cart.request.connection.error")
                      : t("cart.empty.message")}
                  </EmptyPageHeading>
                  <EmptyPageImage>
                    <use href="#cart" xlinkHref="#cart" />
                  </EmptyPageImage>
                </EmptyCartTopWrapper>
                <Footer>
                  <BackButtonWrapper className="container">
                    {cartHasRequestErrors ? (
                      <BackButtonStyled
                        type="button"
                        className="button button--primary button--small button--with-icon"
                        isCentered={true}
                        isMiniCart={true}
                        onClick={reloadCart}
                      >
                        {t("cart.request.reload.button")}
                      </BackButtonStyled>
                    ) : (
                      <BackButton
                        isCentered={true}
                        className="button button--primary button--full-width button--large"
                        isMiniCart={true}
                        onClick={closeMiniCart}
                      />
                    )}
                  </BackButtonWrapper>
                </Footer>
              </EmptyCartWrapper>
            )}
          </MiniCartWrapper>
        </MiniCartOverlay>
      )}
    </>
  );
};
