import React, { useCallback } from "react";
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import { ServiceProducts } from "next-js-app/src/components/common/ServiceProducts/ServiceProducts";
import type { ServiceProductsProps } from "next-js-app/src/components/common/ServiceProducts/types";
import { usePortal } from "../../usePortal/usePortal";

const useServices = () => {
  const { showPortal, hidePortal, portal } = usePortal({
    rootElementId: "js-react-cart-services",
  });
  const openServices = useCallback(
    ({
      categoryId,
      onClose,
      parentCartId,
      serviceProducts,
    }: Omit<ServiceProductsProps, "isModalOpen" | "closeModal"> & {
      onClose?: () => void;
    }) => {
      showPortal(
        <ServiceProducts
          categoryId={categoryId}
          closeModal={() => {
            hidePortal();
            if (onClose !== undefined) {
              onClose();
            }
          }}
          isModalOpen={true}
          parentCartId={parentCartId}
          serviceProducts={serviceProducts}
        />
      );
    },
    [hidePortal, showPortal]
  );

  return {
    openServices,
    ServicesComponent: portal,
  };
};

export { useServices };
