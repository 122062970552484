import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import { Text } from "@/react-components/Text/Text";
import { addXXLEventListener } from "@/react-utils/xxl-event";
import { Skeleton } from "@mui/material";
import type { AvailabilityData, ProductData } from "@xxl/product-search-api";
import { useEffect, useState } from "react";
import { getPreferredStores } from "../helpers";
import { useStoresQuery } from "../queries/useStoresQuery";
import { useGeoSortedStoresWithCollectableProductQuery } from "../queries/useStoresWithCollectableProductQuery";
import type { StockLevel, Store } from "../types";
import { StockLevels } from "../types";
import { StoreStockDialog } from "./StoreStockDialog/StoreStockDialog";
import { NotInStockMessageStyled } from "./StoreStockNotInStockMessage";
import { StoreStockLevelElement } from "./StoreStockStatus";
import {
  StoreStockChooseProductSizeMessage,
  StoreStockHeader,
  StoreStockListItem,
  StoreStockListStyled,
  StoreStockSeeAllStoresButton,
} from "./StoreStockStyles";

const { spaces } = xxlTheme;
const SORTES_STORE_DISPLAY_COUNT = 3;

const getStoreStockList = (
  stores: Store[],
  availabilityData: AvailabilityData[]
) => {
  const preferredStores = getPreferredStores(stores);
  const resultsMap = new Map<
    string,
    { name: string; id: string; stockStatus: StockLevel }
  >();
  for (const store of [...preferredStores, ...stores]) {
    const storeStockStatus =
      availabilityData.find((a) => a.key === store.id)?.stockStatus ??
      StockLevels.OUT_OF_STOCK;

    // skipping stores with no stock
    if (storeStockStatus === StockLevels.OUT_OF_STOCK) {
      continue;
    }
    if (resultsMap.size >= SORTES_STORE_DISPLAY_COUNT) {
      break;
    }

    resultsMap.set(store.id, {
      id: store.id,
      name: store.name,
      stockStatus: storeStockStatus,
    });
  }
  return Array.from(resultsMap.values());
};

const getAvailabilityStockStatus = (storeId: string, ad: AvailabilityData[]) =>
  ad.find((a) => a.key === storeId)?.stockStatus ?? StockLevels.OUT_OF_STOCK;

const useToggleDialogEvent = (cb: () => void) => {
  useEffect(() => {
    addXXLEventListener("OPEN_STORE_STOCK_MODAL", cb);
    return () => removeEventListener("OPEN_STORE_STOCK_MODAL", cb);
  }, [cb]);
};

const StoreStockHeading = ({ text }: { text: string }) => {
  return (
    <StoreStockHeader>
      <Text typography="baseBold" role="heading" aria-level={2}>
        {text}
      </Text>
    </StoreStockHeader>
  );
};

type Props = {
  selectedVariantCode: string | null;
  product: ProductData;
};
const StoreStock = ({ selectedVariantCode, product }: Props) => {
  const { t } = useTranslations();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
  useToggleDialogEvent(toggleDialog);

  const { data: sortedStores = [] } =
    useGeoSortedStoresWithCollectableProductQuery(selectedVariantCode);

  const { data: storesData = [], isLoading: isLoadingStores } =
    useStoresQuery();

  const selectedVariant =
    product.variants.find((v) => v.code === selectedVariantCode) ?? null;

  const isInStock =
    selectedVariant?.stockStatus === StockLevels.IN_STOCK ||
    selectedVariant?.stockStatus === StockLevels.LOW_STOCK;

  const shouldShowStoreStock = selectedVariantCode !== null && isInStock;

  if (isLoadingStores) {
    return (
      <XxlStack>
        <StoreStockHeading text={t("product.details.storestock.status")} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </XxlStack>
    );
  }

  if (selectedVariantCode === null) {
    return (
      <XxlStack>
        <StoreStockHeading text={t("product.details.storestock.status")} />
        <StoreStockChooseProductSizeMessage>
          {t("product.details.storestock.choose.product")}
        </StoreStockChooseProductSizeMessage>
      </XxlStack>
    );
  }

  return (
    <XxlStack>
      <StoreStockHeading text={t("product.details.storestock.status")} />
      {shouldShowStoreStock ? (
        <XxlStack gap={spaces.smallRegular}>
          <StoreStockListStyled>
            {getStoreStockList(storesData, selectedVariant.availability).map(
              (store) => (
                <StoreStockListItem key={store.id}>
                  {store.name}
                  <StoreStockLevelElement
                    stockLevel={getAvailabilityStockStatus(
                      store.id,
                      selectedVariant.availability
                    )}
                  />
                </StoreStockListItem>
              )
            )}
          </StoreStockListStyled>
          <StoreStockSeeAllStoresButton
            onClick={toggleDialog}
            data-testid="showAllStores"
          >
            <Icon name="Store" size={20} />
            <Text>{t("product.details.storestock.status.all")}</Text>
          </StoreStockSeeAllStoresButton>
        </XxlStack>
      ) : (
        <NotInStockMessageStyled
          testId="storestock-out-all"
          messageText={t("product.details.storestock.out.all")}
        />
      )}
      <StoreStockDialog
        sortedStores={sortedStores}
        isDialogOpen={isDialogOpen}
        toggleDialog={toggleDialog}
      />
    </XxlStack>
  );
};

export { StoreStock };
