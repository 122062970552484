import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Skeleton } from "@mui/material";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { AvailabilityData } from "@xxl/product-search-api";
import type { StockStatus } from "@xxl/search-api";
import { useStockStatusTranslation } from "../../hooks/useStockStatusTranslation";
import type { Store } from "../../types";
import { AvailabilityDisplay } from "../AvailabilityDisplay/AvailabilityDisplay";
import { getHighestStockStatusFromStores } from "./PosAvailability.helper";

type PosAvailabilityProps = {
  isLoading: boolean;
  onBtnClick: () => void;
  stores: Store[];
  preferredStores?: Store[];
  storesWithStockCount: number;
  variantAvailabilityData: AvailabilityData[];
};

const testId = "availability-pos";

/**
 * Point of Sale availability component.
 * Displays a short list of stores with their stock status.
 */
export const PosAvailability = ({
  isLoading = false,
  onBtnClick,
  preferredStores,
  stores,
  storesWithStockCount,
  variantAvailabilityData,
}: PosAvailabilityProps) => {
  const { t } = useTranslations();
  const { storeFn } = useStockStatusTranslation();

  const getTranslation = (nrOfStores: number) => {
    if (nrOfStores > 1) {
      return t({
        key: "product.details.clickcollect.available.stores.other",
        messageArguments: [nrOfStores.toString()],
      });
    }
    if (nrOfStores === 1) {
      return t({
        key: "product.details.clickcollect.available.stores.one",
        messageArguments: [nrOfStores.toString()],
      });
    }
    return t("product.details.clickcollect.not.available");
  };

  if (stores.length === 0 || isLoading) {
    return (
      <XxlStack my={"12px"}>
        <Skeleton />
      </XxlStack>
    );
  }

  if (isNotNullOrUndefined(preferredStores) && preferredStores.length > 0) {
    const highestStockStatus = getHighestStockStatusFromStores(
      preferredStores.map((s) => s.id),
      variantAvailabilityData
    );

    const translation = storeFn({
      isMultiPreferredStores: preferredStores.length > 1,
      storeName: preferredStores[0].name,
      storesWithStockCount,
      highestStoreStockStatus: highestStockStatus,
    });

    return (
      <AvailabilityDisplay
        id={testId}
        onBtnClick={storesWithStockCount > 0 ? onBtnClick : () => null}
        stockStatus={highestStockStatus}
        text={translation}
      />
    );
  }

  return (
    <AvailabilityDisplay
      id={testId}
      onBtnClick={storesWithStockCount > 0 ? onBtnClick : () => null}
      stockStatus={getHighestStockStatusFromStores(
        stores.map((s) => s.id),
        variantAvailabilityData
      )}
      text={getTranslation(storesWithStockCount)}
    />
  );
};

export const PosExcludedFromClickAndCollect = () => {
  const { t } = useTranslations();
  return (
    <AvailabilityDisplay
      id={testId}
      stockStatus={null}
      text={t("product.details.clickcollect.service.not.available")}
    />
  );
};

export const PosAvailabilityInitialStockLevels = ({
  initPosStockStatus,
  onBtnClick,
}: {
  initPosStockStatus: StockStatus;
  onBtnClick: () => void;
}) => {
  const { t } = useTranslations();
  const translationKey =
    initPosStockStatus === "OUTOFSTOCK"
      ? "product.details.clickcollect.service.not.available"
      : (`product.details.storestock.${initPosStockStatus === "INSTOCK" ? "in.stock" : "low"}` as const);

  return (
    <AvailabilityDisplay
      id={testId}
      onBtnClick={onBtnClick}
      text={t(translationKey)}
      stockStatus={initPosStockStatus}
    />
  );
};
