/* eslint-disable import/no-unused-modules */
import { DEFAULT_PICKUP_READY_HOURS } from "@/react-app/constants";
import type { Translate } from "@/react-app/contexts/Translations/TranslationsContext";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import type { TranslationKey } from "@/react-app/translations";
import type { StockLevel } from "../types";
import { StockLevels } from "../types";

const online = (stockStatus: StockLevel, t: Translate) => {
  const translationKey = {
    [StockLevels.IN_STOCK]: "general.available.online.many",
    [StockLevels.LOW_STOCK]: "general.available.online.few",
    [StockLevels.OUT_OF_STOCK]: "general.available.online.none",
  }[stockStatus] as TranslationKey;

  return t({ key: translationKey });
};

export const getTranslationKey = ({
  stockLevel,
  isMultiPreferredStores,
  storesWithStockCount,
}: {
  stockLevel: StockLevel;
  isMultiPreferredStores: boolean;
  storesWithStockCount: number;
}) => {
  if (storesWithStockCount === 0) {
    return "product.details.clickcollect.stock.status.not.available.in.store";
  }

  if (isMultiPreferredStores) {
    switch (stockLevel) {
      case StockLevels.IN_STOCK:
        return "product.details.clickcollect.sizeselect.store.stock.status.available.store.multi.preferredstores";
      case StockLevels.LOW_STOCK:
        return "product.details.clickcollect.sizeselect.store.stock.status.available.few.store.multi.preferredstores";
      default:
        return "product.details.clickcollect.sizeselect.store.stock.status.not.available.store.multi.preferredstores";
    }
  }

  switch (stockLevel) {
    case StockLevels.IN_STOCK:
      return "product.details.clickcollect.stock.status.available.store.single";
    case StockLevels.LOW_STOCK:
      return "product.details.clickcollect.sizeselect.store.stock.status.available.few.store.multi.preferredstore";
    default:
      return "product.details.clickcollect.sizeselect.store.stock.status.not.available.store.single.preferredstore";
  }
};

export const getMessageArguments = ({
  storeName,
  storesWithStockCount,
}: {
  storeName: string;
  storesWithStockCount: number;
}) => {
  if (storesWithStockCount === 0) {
    return [];
  }
  return [
    storeName,
    storesWithStockCount.toString(),
    DEFAULT_PICKUP_READY_HOURS.toString(),
  ];
};

type Args = {
  t: Translate;
  storeName: string;
  storesWithStockCount: number;
  isMultiPreferredStores: boolean;
  highestStoreStockStatus: StockLevel;
};

const store = ({
  t,
  storeName,
  storesWithStockCount,
  isMultiPreferredStores = false,
  highestStoreStockStatus,
}: Args) => {
  const key = getTranslationKey({
    stockLevel: highestStoreStockStatus,
    isMultiPreferredStores,
    storesWithStockCount,
  });
  const messageArguments = getMessageArguments({
    storeName,
    storesWithStockCount,
  });

  return t({
    key,
    messageArguments,
  });
};

export const useStockStatusTranslation = () => {
  const { t } = useTranslations();
  return {
    onlineFn: (stockStatus: StockLevel) => online(stockStatus, t),
    storeFn: (args: Omit<Args, "t">) => store({ ...args, t }),
  };
};
