import { PRODUCT_API_CLIENT_IDS } from "@/react-app/constants";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { useSessionSource } from "@/react-app/contexts/Session";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { useElevateRequestData } from "@/react-hooks/useElevateRequestData/useElevateRequestData";
import {
  getSiteHost,
  legacySiteUidToSiteUid,
} from "@/react-utils/xxl-shared-data";
import { useQuery } from "@tanstack/react-query";
import { isNullOrUndefined, type Site } from "@xxl/common-utils";
import { useStateValue } from "cotton-box-react";
import {
  enhanceProductForMultipack,
  getSerializableProductPageResult,
  type SerializableProductPageResult,
} from "./../../../utils/search-api-utils/product-data-maps";
import { QUERY_KEYS } from "./queryKeys";

type UseElevateProductPageQueryProps =
  | {
      variant: "WithInitialData";
      initialData: SerializableProductPageResult;
    }
  | {
      variant: "WithArticleNumber";
      articleNumber: string;
    };

export const useElevateProductPageQuery = (
  args: UseElevateProductPageQueryProps
) => {
  const { siteUid } = useSharedData().data;
  const isLoggedIn = useStateValue(useSessionSource);
  const { productApi } = useApiClients();
  const { getBaseQuery } = useElevateRequestData();
  const productKey =
    args.variant === "WithInitialData"
      ? args.initialData.baseProduct.products.find(
          ({ isSelected }) => isSelected
        )?.code ?? args.initialData.baseProduct.articleNumber
      : args.articleNumber;
  const fetchData = async () => {
    if (isNullOrUndefined(productKey)) {
      return Promise.reject(
        new Error("No article number found cannot fetch data")
      );
    }

    try {
      const baseProduct = await productApi.getBaseProduct({
        ...getBaseQuery(),
        key: productKey,
        clientId: PRODUCT_API_CLIENT_IDS.getBaseProductResultsClientSide,
      });

      if (baseProduct === undefined) {
        throw Error("BaseProduct is undefined.");
      }

      const productForSerialization = enhanceProductForMultipack({
        baseProduct,
        site: getSiteHost(legacySiteUidToSiteUid(siteUid)) as Site,
      });

      return getSerializableProductPageResult({
        baseProduct: productForSerialization,
      });
    } catch (error) {
      throw new Error(`Failed to fetch product page data.`);
    }
  };

  return useQuery({
    queryKey: [QUERY_KEYS.ELEVATE_PRODUCT_PAGE, { isLoggedIn, productKey }],
    queryFn: fetchData,
    ...(args.variant === "WithInitialData" && {
      placeholderData: args.initialData,
    }),
    ...(args.variant === "WithInitialData" &&
      !isLoggedIn && {
        initialData: args.initialData,
      }),
  });
};
