import type { AuthApi } from "@xxl/frontend-api";
import type { EcomSiteUidLegacy } from "react-app/src/global";
import { getAuthData } from "react-app/src/graphql/getAuthData";
import type { CustomersApi } from "@xxl/customers-api";
import { legacySiteUidToSiteUid } from "react-app/src/utils/xxl-shared-data";
import type { Translate } from "react-app/src/contexts/Translations/TranslationsContext";
import stringFormat from "string-format";
import {
  StockLevels,
  type StockLevel,
} from "react-app/src/components/ClickAndCollect/api/api";
import type { PdpState } from "@/react-components/ProductDetailsPage/ProductDetailsPage.state";
import { log } from "@xxl/logging-utils";

export const postNotificationRequest = async (
  authApi: AuthApi,
  customersApi: CustomersApi,
  siteUid: EcomSiteUidLegacy,
  articleNumber: string,
  ean: string
) => {
  try {
    const authData = await getAuthData(authApi);
    if (authData === undefined) {
      log.error("Could not fetch auth data.");
      return;
    }

    void (await customersApi.postProductNotification(
      legacySiteUidToSiteUid(siteUid),
      authData.accessToken,
      {
        articleNumber,
        ean,
      }
    ));
  } catch (error) {
    log.error("Could not set size reminder.", error);
  }
};

export const getStoreTranslationText = (
  t: Translate,
  storeName: string,
  nrOfStoresWithStock: number,
  storeStatus: StockLevel,
  preferredStoresCount: number,
  pdpState: PdpState
) => {
  if (preferredStoresCount >= 2) {
    switch (storeStatus) {
      case StockLevels.IN_STOCK:
        return t(
          "product.details.clickcollect.sizeselect.store.stock.status.available.store.multi.preferredstores"
        );
      case StockLevels.OUT_OF_STOCK:
        return t(
          "product.details.clickcollect.sizeselect.store.stock.status.not.available.store.multi.preferredstores"
        );
      case StockLevels.LOW_STOCK:
        return t(
          "product.details.clickcollect.sizeselect.store.stock.status.available.few.store.multi.preferredstores"
        );
    }
  }

  if (preferredStoresCount === 1) {
    switch (storeStatus) {
      case StockLevels.OUT_OF_STOCK:
        return nrOfStoresWithStock === 0
          ? t(
              "product.details.clickcollect.stock.status.not.available.in.store"
            )
          : stringFormat(
              t(
                "product.details.clickcollect.sizeselect.store.stock.status.not.available.store.single.preferredstore"
              ),
              storeName,
              nrOfStoresWithStock.toString()
            );
      case StockLevels.IN_STOCK:
        return stringFormat(
          t(
            "product.details.clickcollect.sizeselect.store.stock.status.available.store.multi.preferredstore"
          ),
          storeName,
          nrOfStoresWithStock.toString(),
          pdpState.clickAndCollectPickupReadyHours.toString()
        );
      case StockLevels.LOW_STOCK:
        return stringFormat(
          t(
            "product.details.clickcollect.sizeselect.store.stock.status.available.few.store.multi.preferredstore"
          ),
          storeName,
          nrOfStoresWithStock.toString(),
          pdpState.clickAndCollectPickupReadyHours.toString()
        );
    }
  }

  return stringFormat(
    t(
      storeStatus === StockLevels.IN_STOCK
        ? "product.details.clickcollect.stock.status.available.store"
        : "product.details.clickcollect.stock.status.available.few.store"
    ),
    storeName,
    "0", // not used, but needed for consistency in args
    pdpState.clickAndCollectPickupReadyHours.toString()
  );
};
