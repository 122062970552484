import { SkeletonWrapper } from "@/react-components/Common/SkeletonWrapper/SkeletonWrapper";
import { XxlStack } from "@/react-components/Common/XxlStack";
import noop from "lodash/noop";
import * as React from "react";
import { StoreStockStatusIndicator } from "../../StoreStock/StoreStockStyles";
import type { StockLevel } from "../../types";
import { StyledText } from "../styles";

type AvailabilityDisplayProps = {
  id: string;
  isLoading?: boolean;
  stockStatus: StockLevel | null;
  text: string;
  onBtnClick?: () => void;
};

const AvailabilityDisplay: React.FunctionComponent<
  AvailabilityDisplayProps
> = ({ id, onBtnClick = noop, stockStatus, text, isLoading = false }) => {
  const onClick = () => {
    onBtnClick();
  };

  return (
    <SkeletonWrapper isLoading={isLoading}>
      <XxlStack direction={"row"} alignItems={"baseline"}>
        <StoreStockStatusIndicator stockStatus={stockStatus} />
        <StyledText
          data-testid={id}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
          onClick={onClick}
        />
      </XxlStack>
    </SkeletonWrapper>
  );
};

export { AvailabilityDisplay };
