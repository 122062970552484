import type { AvailabilityData } from "@xxl/product-search-api";
import type { StockLevel } from "../../types";
import { StockLevels } from "../../types";

export const getHighestStockStatusFromStores = (
  storeIds: string[],
  availabilityData: AvailabilityData[] = []
) => {
  const stockStatusPriority = [
    StockLevels.IN_STOCK,
    StockLevels.LOW_STOCK,
    StockLevels.OUT_OF_STOCK,
  ];
  const storeStockStatuses: StockLevel[] = [];

  for (const id of storeIds) {
    const store = availabilityData.find((a) => a.key === id) ?? null;
    if (store === null || store.channel !== "STORE") {
      continue;
    }
    storeStockStatuses.push(store.collectableStockStatus);
  }

  return (
    stockStatusPriority.find((status) => storeStockStatuses.includes(status)) ??
    StockLevels.OUT_OF_STOCK
  );
};
