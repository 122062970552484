import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { type DeviceProps } from "@/react-app/global";
import spaces from "@/react-app/styles/theme/spaces";
import { XxlStack } from "@/react-components/Common/XxlStack/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import type { Store as StoreDetails } from "@xxl/content-api";
import * as React from "react";
import { StockLevels, type Store } from "../../types";
import {
  hasStock,
  isStoreDifferentThanSelected,
  storeHasStock,
  type CncInfo,
} from "../StoreStock.helper";
import { StoreStockListItemName } from "../StoreStockListItemName";
import { StoreStockSelectedDetailsSection } from "../StoreStockSelectedDetailsSection";
import { StoreStockLevelElement } from "../StoreStockStatus";
import {
  HeaderWrapper,
  Messages,
  StoreStockClickableListItem,
  StoreStockListStyled,
  StoreStockListSublistHeader,
} from "../StoreStockStyles";
import { Header } from "./Header/Header";

export type StockType = "COLLECTABLE" | "STORE_STOCK";

type StoreStockListProps = DeviceProps & {
  stores: Store[];
  onSelectStore: (stockStatusInStore: Store) => void;
  selectedStoreDetails?: StoreDetails;
  onlyShowStoresWithStock?: boolean;
  cncInfo?: CncInfo;
  preferredStores?: Store[];
  storeFromCart?: Store;
  selectedStore?: Store;
};

const StoreStockList = ({
  cncInfo,
  stores,
  onSelectStore,
  preferredStores,
  onlyShowStoresWithStock = false,
  storeFromCart,
  isMobile,
  selectedStore,
  selectedStoreDetails,
}: StoreStockListProps) => {
  const [expandedStoreId, setExpandedStoreId] = React.useState<string>("");
  const [filteredStores, setFilteredStores] = React.useState<Store[]>();
  const [isDetailsExpanded, setIsDetailsExpanded] = React.useState(false);
  const { t } = useTranslations();

  const preferredStoresIds = preferredStores?.map(({ id }) => id) ?? [];
  const showPreferredStoresSeparately =
    filteredStores !== undefined && preferredStoresIds.length > 0;

  const handleChange = (clickedStore: Store) => (): void => {
    const isExpanded = expandedStoreId === clickedStore.id;

    if (
      !hasStock(clickedStore) ||
      isExpanded ||
      isStoreDifferentThanSelected(storeFromCart, clickedStore.id)
    ) {
      return;
    }

    setExpandedStoreId(clickedStore.id);
    onSelectStore(clickedStore);
  };

  React.useEffect(() => {
    setExpandedStoreId(storeFromCart?.id ?? selectedStore?.id ?? "");
  }, [selectedStore, storeFromCart]);

  React.useEffect(() => {
    const storesWithStock = stores.filter(hasStock);
    setFilteredStores(onlyShowStoresWithStock ? storesWithStock : stores);
  }, [onlyShowStoresWithStock, stores]);

  React.useEffect(() => {
    if (filteredStores === undefined || storeFromCart !== undefined) {
      return;
    }

    const preferredStoreWithStock =
      preferredStores?.find(storeHasStock) ?? preferredStores?.find(hasStock);

    const store =
      selectedStore ??
      filteredStores.find(({ id }) => id === preferredStoreWithStock?.id) ??
      filteredStores.find(hasStock) ??
      undefined;

    if (store !== undefined) {
      onSelectStore(store);
    }
  }, [
    preferredStores,
    filteredStores,
    storeFromCart,
    selectedStore,
    onSelectStore,
  ]);

  const renderStoreListItem = (currentStore: Store) => {
    const currentStoreId = currentStore.id;
    const isDisabled =
      currentStore.stockLevel === StockLevels.OUT_OF_STOCK ||
      (storeFromCart !== undefined && storeFromCart.id !== currentStoreId);

    if (!isMobile) {
      return (
        <StoreStockClickableListItem
          onClick={handleChange(currentStore)}
          key={currentStoreId}
          isActive={currentStoreId === expandedStoreId}
          isDisabled={isDisabled}
        >
          <Header
            cncInfo={cncInfo}
            expandedStoreId={expandedStoreId}
            displayName={currentStore.name}
            storeId={currentStoreId}
            stockLevel={currentStore.stockLevel}
          />
        </StoreStockClickableListItem>
      );
    }

    return (
      <Accordion
        disabled={isDisabled}
        expanded={currentStoreId === expandedStoreId}
        key={currentStoreId}
        onChange={handleChange(currentStore)}
        disableGutters={true}
        sx={{
          borderTop: "1px solid var(--color-xxl-light-gray)",
          borderBottom: "1px solid var(--color-xxl-light-gray)",
          boxShadow: "none",
          "&:first-of-type": {
            borderTop: 0,
            ".MuiAccordionSummary-root": {
              minHeight: spaces.big,
            },
            ".MuiAccordionSummary-content": {
              margin: 0,
            },
          },
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
          "&.expanded": {
            margin: "auto",
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent",
          },
        }}
      >
        <AccordionSummary
          aria-controls={`content-${currentStoreId}`}
          id={`summary-${currentStoreId}`}
          sx={{
            fontWeight:
              currentStoreId === expandedStoreId ? "bolder" : "normal",
            padding: 0,
            margin: 0,
            marginHeight: "fit-content",

            "&:first-of-type": {
              marginTop: 0,
            },
            ".MuiAccordionSummary-content": {
              margin: 0,
              padding: 0,
            },
          }}
        >
          <HeaderWrapper>
            <StoreStockListItemName
              id={currentStoreId}
              checked={currentStoreId === expandedStoreId}
              name={currentStore.name}
            />
            <XxlStack px={"30px"}>
              <StoreStockLevelElement stockLevel={currentStore.stockLevel} />
              {cncInfo !== undefined && (
                <Messages isVisible={currentStoreId === expandedStoreId}>
                  <p>{cncInfo.pickupTimeMessage}</p>
                  <p>{cncInfo.specialOpeningHoursMessage}</p>
                </Messages>
              )}
            </XxlStack>
          </HeaderWrapper>
        </AccordionSummary>
        <Accordion
          expanded={isDetailsExpanded}
          disableGutters={true}
          onChange={() => setIsDetailsExpanded(!isDetailsExpanded)}
          key={currentStoreId}
          sx={{
            border: "none",
            boxShadow: "none",
            padding: `0 0 ${spaces.miniMicro} ${spaces.extraLarge}`,
            margin: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<Icon name="CaretDown" />}
            aria-controls={`content2-${currentStoreId}`}
            id={`summary2-${currentStoreId}`}
            sx={{
              padding: `${spaces.miniMicro} 0`,
              minHeight: "fit-content",

              ".MuiAccordionSummary-content": {
                marginTop: 0,
                marginBottom: 0,
              },
              ".MuiAccordionSummary-expandIconWrapper": {
                width: `${spaces.large}`,
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            {t("general.address.and.opening.hours")}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
            }}
          >
            {currentStoreId === expandedStoreId && (
              <StoreStockSelectedDetailsSection
                isMobile={isMobile}
                store={selectedStoreDetails as StoreDetails}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Accordion>
    );
  };

  return (
    <StoreStockListStyled>
      {filteredStores !== undefined &&
        (!showPreferredStoresSeparately ? (
          filteredStores.map(renderStoreListItem)
        ) : (
          <>
            <StoreStockListSublistHeader>
              {t("storeselect.selected.stores.header")}
            </StoreStockListSublistHeader>
            {filteredStores
              .filter(({ id }) => preferredStoresIds.includes(id))
              .map(renderStoreListItem)}
            <XxlStack padding={"6px"} />
            <StoreStockListSublistHeader>
              {t("storeselect.other.stores.header")}
            </StoreStockListSublistHeader>
            {filteredStores
              .filter(({ id }) => !preferredStoresIds.includes(id))
              .map(renderStoreListItem)}
          </>
        ))}
    </StoreStockListStyled>
  );
};

export { StoreStockList };
