import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { getPreferredStoresCookie } from "@/react-app/utils/Cookie";
import {
  BOX_UNIT,
  PACKAGE_UNIT,
  PIECE_UNIT,
} from "@/react-components/Product/product-helper";
import { getCartDetailsCookie } from "@/react-utils/Cookie/XXLCookie";
import type { CountdownTimerSettings } from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import type {
  AvailabilityData,
  ImageData,
  ProductData,
  StoreAvailabilityData,
  VariantData,
} from "@xxl/product-search-api";
import type { CampaignCountdownProps, Store } from "./types";

const PRIMARY_IMAGE_TYPE = "primary";
export const getPrimaryImg = (imgData: ImageData[]) =>
  imgData.find((i) => i.tags.includes(PRIMARY_IMAGE_TYPE));

export const getComponentSpacing = (isLaptopSize: boolean) =>
  isLaptopSize ? xxlTheme.spaces.large : xxlTheme.spaces.smallRegular;

const isStoreAvailabilityData = (
  a: AvailabilityData
): a is StoreAvailabilityData =>
  a.channel === "STORE" && a.key !== "CENTRAL_WAREHOUSE";

export const getStoresWithStockCount = (a: AvailabilityData[] = []) =>
  a.reduce((acc, store) => {
    if (
      isStoreAvailabilityData(store) &&
      store.collectableStockStatus !== "OUTOFSTOCK"
    ) {
      acc += 1;
    }
    return acc;
  }, 0);

export const getCurrentStoreIdFromCookie = () =>
  getCartDetailsCookie()?.collectStore;

export const getPreferredStores = (stores: Store[]) => {
  const psCookie = getPreferredStoresCookie();
  if (psCookie === null || stores.length === 0) {
    return [] as Store[];
  }
  return Array.from(
    new Set(stores.filter(({ id }) => psCookie.ids.includes(id)))
  );
};

export const createTimerProps = (
  settings: CountdownTimerSettings | null,
  fontColor?: string
): CampaignCountdownProps | null => {
  if (settings === null) {
    return null;
  }
  const { date, startDate, isOnlyHours } = settings;

  if (date === undefined) {
    log.error("Countdown property 'date' is undefined");
    return null;
  }

  return {
    convertDaysToHours: isOnlyHours ?? false,
    endDate: new Date(date),
    fontColor: fontColor ?? xxlTheme.colors.xxlWebBlack,
    startDate: startDate !== undefined ? new Date(startDate) : undefined,
  };
};

export const getSelectedOrFirstVariantSizeCode = (variants: VariantData[]) => {
  const selectedVariant = variants.filter(({ isSelected }) => isSelected);
  return selectedVariant[0]?.sizeCode ?? variants[0]?.sizeCode;
};

export const checkIsArchiveProduct = ({
  isGraveyard,
  isDiscontinued,
}: ProductData) => isGraveyard && !isDiscontinued;

export const getSalesUnit = ({
  type,
  isAmmunition,
}: {
  isAmmunition: ProductData["isAmmunition"];
  type: ProductData["type"];
}) =>
  isAmmunition === true
    ? BOX_UNIT
    : ["MULTIPACK", "PRODUCT_MULTIPACK"].includes(type)
      ? PACKAGE_UNIT
      : PIECE_UNIT;

export const isFrontendSalesUnit = (unit?: string): boolean =>
  unit !== undefined && [PIECE_UNIT, BOX_UNIT, PACKAGE_UNIT].includes(unit);

export const getOnlineStockStatus = (a: VariantData["availability"]) =>
  a.find((item) => item.channel === "ONLINE")?.stockStatus;
