import { useTrackingDataFromURL } from "@/hooks/useTrackingDataFromURL";
import { useTracking } from "@/react-app/contexts/Tracking";
import { cookieCategories } from "@/react-utils/Cookie";
import { toProductCardDataFromBase } from "@/react-utils/ProductData/product-card-data-helper";
import { useMediaQuery } from "@mui/material";
import { isNotNullOrUndefined, isNullOrUndefined } from "@xxl/common-utils";
import { useEffect } from "react";
import { XxlButton } from "react-app/src/components/Common/XxlButton/XxlButton";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { Icon } from "react-app/src/components/Icon/Icon";
import { XXLLoader } from "react-app/src/components/XXLLoader";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import spaces from "react-app/src/styles/theme/spaces";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { laptopMediaQuery } from "react-app/src/utils/xxl-screen";
import { OverviewGridArea } from "src/components/ProductDetailsPage/ProductDetailsPage.styles";
import { useVolumentalMetaData } from "../../hooks/useVolumentalMetaData";
import { createCampaignInfo } from "../../pages/p/pdp.helper";
import { checkIfRequiredConsentsSelected } from "../../utils/invoke-after-consent";
import { ProductImageSlider } from "../Pdp/ProductImageSlider";
import { ProductOverview } from "../Pdp/ProductOverview/ProductOverview";
import { ProductPanel } from "../Pdp/ProductPanel/ProductPanel";
import { ProductStyleSelector } from "../Pdp/ProductStyleSelector/ProductStyleSelector";
import {
  getSalesUnit,
  getSelectedOrFirstVariantSizeCode,
  isFrontendSalesUnit,
} from "../Pdp/helpers";
import { useCampaignDataQuery } from "../Pdp/queries/useCampaignDataQuery";
import { useElevateProductPageQuery } from "../Pdp/queries/useElevateProductPageQuery";
import { usePaymentWidgetQuery } from "../Pdp/queries/usePaymentWidgetQuery";
import {
  getComponentSpacing,
  pushElevateProductViewEvent,
} from "../ProductDetailsPage/ProductDetailsPage.helper";
import { SeeFullProductPage } from "./MiniPdp.styles";

const IMAGE_MAX_WIDTH_ONE_PRODUCT_IMAGE = 420;
const IMAGE_MAX_WIDTH_MULTIPLE_PRODUCT_IMAGES = 460;
const PRODUCT_STYLES_MAX_WIDTH = 491;

type Props = {
  handleDialogBoxClosing?: () => void;
  isQuickShop: boolean;
  productCode: string;
};

const MiniElevatePdpContent = ({
  handleDialogBoxClosing,
  isQuickShop,
  productCode,
}: Props) => {
  const trackers = useTracking();
  const canSendTrackingEvents = checkIfRequiredConsentsSelected([
    cookieCategories.STATISTIC,
  ]);
  const isLaptopSize = useMediaQuery(laptopMediaQuery);
  const componentSpacing = getComponentSpacing({ isLaptopSize });
  const { t } = useTranslations();
  const {
    data: pdpData,
    isError,
    isLoading,
  } = useElevateProductPageQuery({
    variant: "WithArticleNumber",
    articleNumber: productCode,
  });
  const volumentalMetaData = useVolumentalMetaData({
    breadcrumbs: pdpData?.navigation?.breadcrumbs,
  });
  const { gtmProductListName } = useTrackingDataFromURL();

  const campaignId = pdpData?.baseProduct.products.at(0)?.campaign?.id;
  const { data: campaignData } = useCampaignDataQuery(campaignId);
  const { data: paymentWidgetConfig } = usePaymentWidgetQuery({
    shouldRunServerSide: false,
  });

  const baseProduct = pdpData?.baseProduct;
  const { articleNumber, products } = baseProduct ?? {};
  const primaryProduct = products?.at(0);

  useEffect(() => {
    if (
      canSendTrackingEvents &&
      primaryProduct !== undefined &&
      gtmProductListName !== null
    ) {
      pushElevateProductViewEvent({
        trackers,
        elevateProduct: primaryProduct,
        listName: gtmProductListName,
      });
    }
  }, [canSendTrackingEvents, gtmProductListName, primaryProduct, trackers]);

  if (
    isLoading ||
    isNullOrUndefined(baseProduct) ||
    isNullOrUndefined(products) ||
    isNullOrUndefined(primaryProduct) ||
    isNullOrUndefined(articleNumber)
  ) {
    return (
      <XxlStack
        justifyContent="center"
        minHeight={`${isLaptopSize ? 50 : 80}vh`}
      >
        <XXLLoader />
      </XxlStack>
    );
  }
  if (isError && isNotNullOrUndefined(handleDialogBoxClosing)) {
    handleDialogBoxClosing();
  }

  const {
    badges,
    brand,
    code,
    configurations,
    imageInfo,
    price,
    salesUnit,
    title,
    url,
    usps,
    variants,
  } = primaryProduct;
  const sizeCode = getSelectedOrFirstVariantSizeCode(variants);
  const product = toProductCardDataFromBase(baseProduct);
  const campaignInfo = createCampaignInfo({
    campaignRibbonContent: product.campaignRibbon?.content,
    campaignData: campaignData ?? null,
    id: campaignId,
  });
  const displaySalesUnit = isFrontendSalesUnit(salesUnit)
    ? salesUnit
    : getSalesUnit(primaryProduct);

  return (
    <XxlStack gap={spaces.large}>
      <XxlStack gap={spaces.large} direction={isLaptopSize ? "row" : "column"}>
        <XxlStack
          maxWidth={
            isLaptopSize
              ? imageInfo.images.length > 1
                ? IMAGE_MAX_WIDTH_MULTIPLE_PRODUCT_IMAGES
                : IMAGE_MAX_WIDTH_ONE_PRODUCT_IMAGE
              : "none"
          }
          width="100%"
        >
          <ProductImageSlider
            badges={badges}
            imageInfo={imageInfo}
            productName={title}
            shouldReplaceThumbnailsWithArrows={true}
            shouldShowUpsaleLink={false}
          />
        </XxlStack>
        <XxlStack
          spacing={
            isLaptopSize ? xxlTheme.spaces.miniMicro : xxlTheme.spaces.micro
          }
          direction="column"
          flexGrow="1"
          width="100%"
          justifyContent="space-between"
        >
          <OverviewGridArea spacing={componentSpacing}>
            <ProductOverview
              articleNumber={articleNumber}
              baseProductName={title}
              brandLogoUrl={brand?.logo ?? ""}
              brandName={brand?.name ?? ""}
              brandUrl={brand?.url ?? ""}
              priceData={price}
              productCode={sizeCode}
              productStyle={code}
              salesUnit={displaySalesUnit}
              usps={isQuickShop ? usps : []}
            />
            <XxlStack mt={"6px"} />
          </OverviewGridArea>
          <style>{
            // Force drawer to appear above modal
            `.MuiDrawer-root { z-index: 1300; }`
          }</style>
          <XxlStack
            gap={
              isLaptopSize
                ? xxlTheme.spaces.smallRegular
                : xxlTheme.spaces.regular
            }
          >
            <XxlStack maxWidth={`${PRODUCT_STYLES_MAX_WIDTH}px`}>
              <ProductStyleSelector products={products} />
            </XxlStack>
            <ProductPanel
              campaignInfo={campaignInfo ?? undefined}
              configurations={configurations}
              hasRelatedGuides={false}
              hasServicePromotions={false}
              isMiniPdp={true}
              paymentProviderMessageConfig={{ isActive: false, locale: "" }}
              paymentWidget={paymentWidgetConfig ?? null}
              products={products}
              volumentalMetaData={volumentalMetaData}
            />
          </XxlStack>
        </XxlStack>
      </XxlStack>

      {typeof url === "string" &&
        (isLaptopSize ? (
          <SeeFullProductPage
            href={url}
            target={isQuickShop ? "_parent" : "_blank"}
            align={isQuickShop ? "end" : "start"}
          >
            {t("product.details.see.full.product.page")}
            <Icon
              name="CaretRight"
              size={16}
              transform={{ translateX: 0, translateY: 4 }}
            />
          </SeeFullProductPage>
        ) : (
          <XxlButton
            variant="secondary"
            onClick={() => window.open(url, isQuickShop ? "_parent" : "_blank")}
          >
            {t("product.details.see.full.product.page")}
          </XxlButton>
        ))}
    </XxlStack>
  );
};

export { MiniElevatePdpContent };
