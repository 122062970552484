import type { PropsWithChildren } from "react";
import React from "react";
import { FilterContextProvider } from "react-app/src/components/Filter/FilterProvider/FilterContextProvider";
import { Header } from "react-app/src/components/Header";
import { getNonUndefinedHeaderLinks } from "react-app/src/components/MegaMenu/Mobile/mega-menu-helper";
import type { Link, MegaMenuContentData } from "@xxl/content-api";
import type { HeaderConfig } from "react-app/src/global";
import { InfoMessage } from "../InfoMessage/InfoMessage";
import type { FooterContent } from "../../utils/apis/content-footer-api";
import type { InfoMessage as InfoMessageType } from "../../utils/apis/content-info-messages";
import SiteFooter from "../Footer/SiteFooter";
import { LimitedHeader } from "react-app/src/components/Header/LimitedHeader";
import { RewardsBanner } from "react-app/src/components/RewardsBanner/RewardsBanner";
import { useStateValue } from "cotton-box-react";
import { useSessionSource } from "react-app/src/contexts/Session";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import BackToTopButton, { Y_OFFSET } from "../BackToTop/BackToTopButton";
import { useInView } from "react-intersection-observer";
import { EmployeeLoginWelcomeModal } from "@/react-components/Reward/Login/EmployeeLoginModal/EmployeeLoginModal";

const { spaces, colors } = xxlTheme;

type LayoutProps = {
  footerContent: FooterContent;
  headerConfig: HeaderConfig;
  infoMessage: InfoMessageType | null;
  megaMenuContent: MegaMenuContentData;
  headerLinks?: Link[];
  shouldShowLimitedHeader?: boolean;
  shouldShowRewardsBanner?: boolean;
  shouldHideFooterTopMargin?: boolean;
};

type MainProps = {
  shouldHideBottomMargin: boolean;
};

const Main = styled.main<MainProps>(
  ({ shouldHideBottomMargin = false }) => css`
    position: relative;
    margin-bottom: ${shouldHideBottomMargin ? 0 : spaces.biggerGiant};
    background-color: ${colors.xxlWhite};
    min-height: 600px;
  `
);

const BackToTopPosition = styled.div`
  &&& {
    height: 0;
  }
`;

export const Layout = ({
  children,
  footerContent,
  headerConfig,
  headerLinks,
  infoMessage,
  megaMenuContent,
  shouldShowLimitedHeader = false,
  shouldShowRewardsBanner = false,
  shouldHideFooterTopMargin = false,
}: PropsWithChildren<LayoutProps>) => {
  const ssrHeaderLinks = getNonUndefinedHeaderLinks(headerLinks ?? []);
  const headerCode = ssrHeaderLinks.length > 0 ? "header" : "teamHeader";
  const { linkListOne, linkListTwo, logo, socialMediaUrls, thirdPartyLogos } =
    footerContent;
  const isLoggedIn = useStateValue(useSessionSource);

  const { ref, inView } = useInView({
    initialInView: true,
    rootMargin: `${Y_OFFSET}px 0px 0px 0px`,
  });

  return (
    <>
      <BackToTopButton isHeaderInView={inView} />
      <BackToTopPosition ref={ref} id="back-to-top-position" />
      <FilterContextProvider>
        {shouldShowLimitedHeader ? (
          <LimitedHeader headerConfig={headerConfig} />
        ) : (
          <Header
            headerCode={headerCode}
            headerConfig={headerConfig}
            initialHeaderLinks={
              ssrHeaderLinks.length > 0 ? ssrHeaderLinks : undefined
            }
            megaMenuContent={megaMenuContent}
          />
        )}
        <Main shouldHideBottomMargin={shouldHideFooterTopMargin}>
          {!shouldShowLimitedHeader && <InfoMessage message={infoMessage} />}
          {children}
          {shouldShowRewardsBanner && !isLoggedIn && <RewardsBanner />}
        </Main>
      </FilterContextProvider>
      <EmployeeLoginWelcomeModal isLoggedIn={isLoggedIn} />
      <SiteFooter
        linkListOne={linkListOne}
        linkListTwo={linkListTwo}
        logo={logo}
        socialMediaUrls={socialMediaUrls}
        thirdPartyLogos={thirdPartyLogos}
      />
    </>
  );
};
