import {
  getSessionKey,
  getCustomerKey as getCustomerKeyFromCookie,
  isFunctionalCookieEnabled,
} from "react-app/src/utils/Cookie/XXLCookie";
import {
  type InjectedScript,
  injectScript,
} from "react-app/src/utils/xxl-load-script";
import zIndex from "@/react-app/styles/theme/zIndex";
import { CustomWindowEvents } from "react-app/src/components/Common/util";
import { log } from "@xxl/logging-utils";
import { getEnvVar } from "@/utils/environment-variables";

const getCustomerKey = (): string => {
  const key = getCustomerKeyFromCookie();
  if (key === null) {
    log.error("customerKey is null");
    return getSessionKey();
  }
  return key;
};

const initKindlyChatbot = (kindlyChatbotKey: string): void => {
  if (typeof window === "undefined") {
    return;
  }

  const scriptData: InjectedScript = {
    src: "https://chat.kindlycdn.com/kindly-chat.js",
    id: "kindly-chat",
    dataParams: [
      {
        name: "data-shadow-dom",
        value: "1",
      },
      {
        name: "data-bot-key",
        value: kindlyChatbotKey,
      },
    ],
    defer: true,
  };

  window.kindlyOptions = {
    zIndex: zIndex["kindly-chat"],
    context: {
      "ve:sessionKey": getSessionKey(),
      "ve:customerKey": getCustomerKey(),
    },
    position: { bottom: "74px" }, // default is 16px
  };

  window.addEventListener(
    CustomWindowEvents.COOKIE_INFORMATION_CONSENT_GIVEN,
    () => {
      if (isFunctionalCookieEnabled()) {
        injectScript(scriptData);
      }
    }
  );
};

const isKindlyEnabledOnPdp = (productCategories: string[]): boolean => {
  const kindlyCategories = getEnvVar(
    "CONFIG_SITE_KINDLYPDPCATEGORIES",
    ""
  ).split(",");
  return (
    productCategories.find((categoryCode) =>
      kindlyCategories.includes(categoryCode)
    ) !== undefined
  );
};

const getProductChatApiKey = (): string =>
  getEnvVar("CONFIG_SITE_KINDLYCHATBOTKEY_PRODUCTCHAT", "");

export { initKindlyChatbot, isKindlyEnabledOnPdp, getProductChatApiKey };
