import { log } from "@xxl/logging-utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { addServiceToEgCart } from "react-app/src/components/Cart/Api/CartAPI";
import type { GenericGraphQLError } from "react-app/src/components/Cart/Api/types";
import { ExpandableDescription } from "react-app/src/components/Common/ExpandableDescription";
import { XxlButton } from "react-app/src/components/Common/XxlButton/XxlButton";
import { DialogBox } from "react-app/src/components/DialogBox";
import { resolutionSizes } from "react-app/src/config";
import { QUANTITY_ONE } from "react-app/src/constants";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { StyledCheckbox } from "react-app/src/styled";
import { dispatchEvent } from "react-app/src/utils/xxl-event";
import { INDEX_OF_RECOMMENDED_SERVICE } from "../../ConfiguratorForProductBundles/constants";
import { useServiceDescription } from "../../ConfiguratorForProductBundles/hooks/useServiceDescription/useServiceDescription";
import { GeneralErrorsList } from "./GeneralErrorsList";
import { useListShadowOnScroll } from "./hooks/useListShadowOnScroll";
import { PurchaseDetails } from "./ServiceProduct.styled";
import { ServiceProductDumb } from "./ServiceProductDumb/ServiceProductDumb";
import {
  Content,
  Footer,
  Header,
  ListWrapper,
  ProductPageButtonsWrapper,
  ServiceProductsList,
  ShadowBottom,
  ShadowsContainer,
  ShadowTop,
  Title,
  Wrapper,
} from "./ServiceProductsWrapper.styled";
import type { ServiceProductsProps } from "./types";
import { UspBar } from "./UspBar";

const ServiceProducts = ({
  categoryId: category,
  closeModal,
  isModalOpen,
  parentCartId,
  serviceProducts,
}: ServiceProductsProps) => {
  const [selectedServiceProductEan, setSelectedServiceProductEan] =
    useState<string>();
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [addToCartErrors, setAddToCartErrors] = useState<GenericGraphQLError[]>(
    []
  );
  const [scrollPosition, setScrollPosition] = useState(0);

  const listViewportRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslations();
  const {
    configuration: {
      amplifyConfig: { aws_appsync_apiKey, aws_appsync_graphqlEndpoint },
    },
  } = useSharedData().data;

  const { isBottomShadowVisible, isTopShadowVisible } = useListShadowOnScroll({
    listRef,
    listViewportRef,
    scrollPosition,
  });
  const { serviceDescription, serviceTitle } = useServiceDescription({
    category,
  });

  useEffect(() => {
    if (isAddingToCart === false) {
      return;
    }
    setAddToCartErrors([]);
  }, [isAddingToCart]);

  useEffect(() => {
    setAddToCartErrors([]);
  }, [selectedServiceProductEan]);

  const handleClickAddToCart = useCallback(async () => {
    if (selectedServiceProductEan === undefined || parentCartId === undefined) {
      throw Error("selectedServiceProductEan or parentCartId is undefined");
    }
    try {
      setIsAddingToCart(true);
      const { data, status } = await addServiceToEgCart(
        selectedServiceProductEan,
        QUANTITY_ONE.toString(),
        parentCartId,
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );
      if (status === "ERROR") {
        const { errors } = data;
        setAddToCartErrors(errors as GenericGraphQLError[]);
        return;
      }
      const { itemsCount } =
        data.data?.addServiceProductsToCartItems.totals ?? {};
      if (typeof itemsCount !== "number") {
        throw Error("itemsCount is not a number");
      }
      dispatchEvent("XXL_SET_CART_COUNT", { count: itemsCount });
      closeModal();
    } catch (error) {
      // TODO: Handle errors in https://xxlsports.atlassian.net/browse/XD-11665
      log.error(error);
    } finally {
      setIsAddingToCart(false);
    }
  }, [
    aws_appsync_apiKey,
    aws_appsync_graphqlEndpoint,
    closeModal,
    parentCartId,
    selectedServiceProductEan,
  ]);

  return (
    <DialogBox
      isDialogBoxOpen={isModalOpen}
      hasPadding={false}
      dialogBoxSize={"md"}
      handleDialogBoxClosing={() => isAddingToCart === false && closeModal()}
      contentStyle={{ overflow: "hidden" }}
      testId="service-product-dialog"
    >
      <Header>{t("services.popup.header.product.page")}</Header>
      <GeneralErrorsList errors={addToCartErrors} />
      <ListWrapper ref={listViewportRef}>
        <ShadowsContainer>
          <ShadowTop isVisible={isTopShadowVisible} />
          <ShadowBottom isVisible={isBottomShadowVisible} />
        </ShadowsContainer>
        <Wrapper
          onScroll={({ target }) => {
            const { scrollTop } = target as HTMLDivElement;
            setScrollPosition(scrollTop);
          }}
        >
          <Content>
            <Title>
              {serviceTitle ?? t("services.popup.category.title.default")}
            </Title>
            <ExpandableDescription
              description={
                serviceDescription ??
                t("services.popup.category.description.default")
              }
              descriptionTestId="service-products-description"
              numberOfLinesToShow={2}
              minResolutionToStopClamping={resolutionSizes.mobile}
            />
            <ServiceProductsList numberOfItems={serviceProducts.length}>
              {serviceProducts.map(
                (
                  {
                    description,
                    brandName,
                    categoryCode,
                    ean,
                    name,
                    priceData,
                  },
                  index
                ) => {
                  return (
                    <ServiceProductDumb
                      brandName={brandName}
                      categoryCode={categoryCode}
                      description={description}
                      isRecommended={index === INDEX_OF_RECOMMENDED_SERVICE}
                      isSelected={false}
                      key={ean}
                      name={name}
                      priceData={priceData}
                      PurchaseDetails={
                        <PurchaseDetails>
                          <StyledCheckbox
                            label={t("services.popup.add.service.label")}
                            handleChange={() =>
                              isAddingToCart === false &&
                              setSelectedServiceProductEan(
                                selectedServiceProductEan === ean
                                  ? undefined
                                  : ean
                              )
                            }
                            isDisabled={
                              selectedServiceProductEan !== undefined &&
                              selectedServiceProductEan !== ean
                            }
                            checked={selectedServiceProductEan === ean}
                          />
                        </PurchaseDetails>
                      }
                    />
                  );
                }
              )}
            </ServiceProductsList>
          </Content>
        </Wrapper>
      </ListWrapper>
      <Footer>
        <ProductPageButtonsWrapper>
          <XxlButton
            disabled={isAddingToCart}
            onClick={closeModal}
            style={{ gridColumnStart: "left" }}
            variant="outlined"
          >
            {t("product.details.clickcollect.continue.shopping")}
          </XxlButton>
          <XxlButton
            disabled={selectedServiceProductEan === undefined || isAddingToCart}
            loading={isAddingToCart}
            onClick={() => void handleClickAddToCart()}
            style={{ gridColumnStart: "right" }}
            icon="Plus"
          >
            {t("product.details.add.to.cart.label")}
          </XxlButton>
        </ProductPageButtonsWrapper>
        <UspBar />
      </Footer>
    </DialogBox>
  );
};

export { ServiceProducts };
