import { hasValue } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import {
  RecommendationPayloadBuilder,
  type BaseProductData,
  type CustomAttributeType,
} from "@xxl/product-search-api";
import { useApiClients } from "../../contexts/ApiClients";
import type {
  AdditionalSales,
  ProductCardDataV2,
} from "../../utils/ProductData/product-card-data-helper";
import {
  getAdditionalSalesProductIds,
  productCardDataToAdditionalSalesProducts,
  sortBaseProducts,
  toProductCardDataFromBase,
} from "../../utils/ProductData/product-card-data-helper";
import { useElevateRequestData } from "../useElevateRequestData/useElevateRequestData";
import { LIMIT } from "./constants";
import {
  hasObjectValues,
  uniqueByArticleNumber,
} from "./useProductRecommendations.helper";

type GetGeneralRecommendationsArgs = {
  variant: "PERSONAL" | "TOP_PRODUCTS";
  baseColors?: string[];
  brandNames?: string[];
  campaignIds?: string[];
  categoryIds?: string[];
  productKeys?: string[];
  productsCount?: number;
  users?: string[];
};

const methods = {
  PERSONAL: "addPersonalRecs",
  TOP_PRODUCTS: "addTopProductsRecs",
} as const;

const useProductRecommendations = () => {
  const { elevateApi } = useApiClients();
  const { getBaseQuery } = useElevateRequestData();

  const getAdditionalSalesProducts = async (
    additionalSales?: AdditionalSales
  ): Promise<{
    accessoryProducts: ProductCardDataV2[];
    crossSalesProducts: ProductCardDataV2[];
    serviceProducts: ProductCardDataV2[];
  }> => {
    const fallback = {
      accessoryProducts: [],
      crossSalesProducts: [],
      serviceProducts: [],
    };

    if (!hasValue(additionalSales)) {
      return fallback;
    }

    const productKeys = hasValue(additionalSales)
      ? getAdditionalSalesProductIds(additionalSales)
      : [];

    if (productKeys.length === 0) {
      log.error("No products keys found.", additionalSales);
      return fallback;
    }

    const response = await elevateApi.storefront.landingPagePOST(
      {
        ...getBaseQuery(),
        pageReference: `/p/getAdditionalSalesProducts`,
        channels: "ONLINE|STORE",
        toggleOverrides: {
          ignoreProductVisibilityRules: true,
        },
      },
      {
        primaryList: {
          include: true,
          productRules: `rule incl product_group_key { "${productKeys.toString().replaceAll(",", '" "')}" }`,
        },
      }
    );

    const products = response.data.primaryList.baseProducts.map(
      toProductCardDataFromBase
    );

    return productCardDataToAdditionalSalesProducts(products, additionalSales);
  };

  const getAddToCartRecs = async (ean: string) => {
    const builder = new RecommendationPayloadBuilder();
    const recommendationLists = builder
      .addAddToCartRecs({
        id: "addToCartRecs1",
        limit: 4,
      })
      .build();

    const response = await elevateApi.storefront.addToCartPopup(
      {
        ...getBaseQuery(),
        variantKey: ean,
        channels: "ONLINE|STORE",
      },
      {
        recommendationLists,
      }
    );

    return response.data.recommendationLists[0];
  };

  const getProductPageRecommendationsData = async ({
    id,
    productKey,
    type,
  }: {
    id: string;
    productKey: string;
    type: "addAddToCartRecs" | "addAlternativesRecs" | "addRecentlyViewedRecs";
  }) => {
    const builder = new RecommendationPayloadBuilder();
    const recommendationLists = builder[type]({
      id,
      limit: LIMIT,
      productKey,
    }).build();

    const { data } = await elevateApi.storefront.productPage(
      {
        ...getBaseQuery(),
        productKey,
        channels: "ONLINE|STORE",
      },
      {
        recommendationLists,
      }
    );
    const { baseProducts = [] } = data.recommendationLists?.find(
      (item) => item.id === id
    ) ?? { baseProducts: [] };

    return baseProducts;
  };

  const getGeneralRecommendations = async ({
    baseColors,
    brandNames,
    campaignIds,
    categoryIds,
    productKeys = [],
    productsCount: limit = LIMIT,
    variant,
    users,
  }: GetGeneralRecommendationsArgs) => {
    const builder = new RecommendationPayloadBuilder();
    const sortedListOfProps = [
      { baseColors },
      { users },
      { brandNames },
      { campaignIds },
      { categoryIds },
    ].filter(hasObjectValues);
    const listOfBuildProps = hasValue(sortedListOfProps)
      ? sortedListOfProps.map((_, index) => ({
          id: `${index}`,
          limit,
          ...sortedListOfProps
            .slice(index) // Each iteration will return less props = higher chance of matching products from product-search-api
            .reduce((object, prop) => ({ ...object, ...prop }), {}),
        }))
      : [{ id: "personalRecs", limit }];
    listOfBuildProps.forEach((buildProps) =>
      builder[methods[variant]](buildProps)
    );
    const recommendationLists = builder.build();
    const isInStockOnlineString: CustomAttributeType = "isInStockOnline";
    const response = await elevateApi.storefront.landingPagePOST(
      {
        ...getBaseQuery(),
        q: productKeys.toString().replaceAll(",", " "),
        pageReference: `/homepage/recommendations`,
        channels: "ONLINE|STORE",
      },
      {
        primaryList: {
          include: hasValue(productKeys),
          productRules: `rule excl custom.${isInStockOnlineString} {"false"}`,
        },
        recommendationLists,
      }
    );

    const {
      primaryList: { baseProducts },
      recommendationLists: recommendationsListsResponse,
    } = response.data;
    const recommendationProducts = recommendationsListsResponse
      .flatMap((recommendation) => recommendation.baseProducts)
      .reduce<BaseProductData[]>(uniqueByArticleNumber, []);
    const sortedManuallyAddedProducts = sortBaseProducts({
      baseProducts,
      sortOrder: productKeys,
    });

    return [...sortedManuallyAddedProducts, ...recommendationProducts].slice(
      0,
      limit
    );
  };

  const getAlternativesRecs = async (productKey: string) =>
    await getProductPageRecommendationsData({
      id: "alternativesRecs",
      productKey,
      type: "addAlternativesRecs",
    });

  const getFrequentlyBoughtTogether = async (productKey: string) =>
    await getProductPageRecommendationsData({
      id: "addToCartRecs",
      productKey,
      type: "addAddToCartRecs",
    });

  const getRecentlyViewed = async (productKey: string) =>
    await getProductPageRecommendationsData({
      id: "recentlyViewedRecs",
      productKey,
      type: "addRecentlyViewedRecs",
    });

  const getPersonalRecs = async ({
    brandNames,
    campaignIds,
    categoryIds,
    productKeys,
    productsCount,
  }: Omit<GetGeneralRecommendationsArgs, "variant">) =>
    getGeneralRecommendations({
      variant: "PERSONAL",
      brandNames,
      campaignIds,
      categoryIds,
      productKeys,
      productsCount,
    });

  const getTopProductsRecs = async ({
    baseColors,
    brandNames,
    campaignIds,
    categoryIds,
    productKeys,
    productsCount,
    users,
  }: Omit<GetGeneralRecommendationsArgs, "variant">) =>
    getGeneralRecommendations({
      variant: "TOP_PRODUCTS",
      baseColors,
      brandNames,
      campaignIds,
      categoryIds,
      productKeys,
      productsCount,
      users,
    });

  /**
   * getCartPageRecommendations returns recommendations based on the cart info provided.
   * @param productEANsInCart array of product EANs. Ex. ["1896787", "2384730"].
   */
  const getCartPageRecommendations = async (productEANsInCart: string[]) => {
    const builder = new RecommendationPayloadBuilder();
    const recommendationLists = builder
      .addCartPageRecs({
        id: "cartPageRecs",
        limit: LIMIT,
      })
      .build();
    const response = await elevateApi.storefront.cartPage(
      {
        ...getBaseQuery(),
        cart: productEANsInCart.toString().replace(",", "|"),
        channels: "ONLINE|STORE",
      },
      {
        recommendationLists,
      }
    );

    return response.data.recommendationLists[0].baseProducts;
  };

  return {
    getAdditionalSalesProducts,
    getAddToCartRecs,
    getAlternativesRecs,
    getCartPageRecommendations,
    getFrequentlyBoughtTogether,
    getPersonalRecs,
    getRecentlyViewed,
    getTopProductsRecs,
  };
};

export { useProductRecommendations };
