import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { StyledCheckbox } from "@/react-app/styled";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import {
  useEffect,
  useState,
  type ChangeEvent,
  type ChangeEventHandler,
} from "react";
import { hasStock, isStoreDifferentThanSelected } from "../StoreStock";
import { Dialog } from "../StoreStock/Dialog/Dialog";
import {
  BodyContainer,
  FormWrapper,
  StoreInput,
  StoreSelectionWrapper,
} from "../StoreStock/StoreStockDialog/StoreStockDialogStyles";
import { getCurrentStoreIdFromCookie } from "../helpers";
import { useStoreDetailsQuery } from "../queries/useStoreDetailsQuery";
import type { Store } from "../types";
import { StoreInCartDialog } from "./StoreInCartDialog";
import { StoreListComponent } from "./StoreListComponent";
import { DialogBtn, DialogFooter } from "./styles";

type Props = {
  onAtcClick: (args: { storeId?: string }) => void;
  isDialogOpen: boolean;
  stores: Store[];
  toggleDialog: () => void;
};

export const CncDialog = ({
  onAtcClick,
  isDialogOpen,
  stores,
  toggleDialog,
}: Props) => {
  const { t } = useTranslations();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const [onlyShowStoresWithStock, setOnlyShowStoresWithStock] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState<string>();
  const [cartStoreId, setCartStoreId] = useState<string>();

  useEffect(() => {
    const getCurrentStoreForPickup = (s: Store[]) => {
      const storeId = getCurrentStoreIdFromCookie();
      return s.find((store) => store.id === storeId);
    };
    const cartStore = getCurrentStoreForPickup(stores);
    setCartStoreId(cartStore?.id);
  }, [isDialogOpen, stores]);

  const onFilterInputChange: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(target.value);
  };

  const handleStoreSelect = (id: string) => setSelectedStoreId(id);
  const handleFilterInStock = (event: ChangeEvent<HTMLInputElement>) =>
    setOnlyShowStoresWithStock(event.target.checked);

  const filteredSortedStores = stores.filter((store) => {
    if (filterValue.length === 0) {
      return true;
    }
    return store.name
      .toLocaleLowerCase()
      .includes(filterValue.toLocaleLowerCase());
  });

  const filteredStoresWithStock = filteredSortedStores.filter(hasStock);
  const storeForPickup = stores.find((s) => s.id === cartStoreId);
  const selectedStore =
    stores.find((s) => s.id === selectedStoreId) ?? storeForPickup;
  const { data: selectedStoreDetails } = useStoreDetailsQuery(selectedStore);

  const handleAddToCartClick = (storeId?: string) => {
    if (storeId === undefined) {
      return;
    }
    onAtcClick({ storeId });
    toggleDialog();
  };

  const isAddToCartBtnDisabled =
    isStoreDifferentThanSelected(
      storeForPickup,
      selectedStoreId ?? undefined
    ) ||
    selectedStoreId === undefined ||
    stores.find((store) => store.id === selectedStoreId)
      ?.collectableStockLevel === "OUTOFSTOCK";

  const shouldShowStoreInCartDialog =
    isDialogOpen && storeForPickup !== undefined;

  return (
    <>
      <StoreInCartDialog
        isOpen={isDialogOpen && shouldShowStoreInCartDialog}
        onClose={toggleDialog}
        isMobile={isMobile}
        storeName={storeForPickup?.name ?? ""}
        onAtcClick={() => {
          onAtcClick({ storeId: storeForPickup?.id });
          toggleDialog();
        }}
        collectableStockLevel={
          storeForPickup?.collectableStockLevel ?? "OUTOFSTOCK"
        }
        storeStockLevel={storeForPickup?.stockLevel ?? "OUTOFSTOCK"}
      />

      <Dialog
        open={isDialogOpen && !shouldShowStoreInCartDialog}
        onClose={toggleDialog}
        heading={t("product.details.clickcollect.title")}
        fullScreen={isMobile}
      >
        <BodyContainer>
          <FormWrapper>
            <StoreInput
              data-testid="store-stock-search-input"
              onChange={onFilterInputChange}
              value={filterValue}
              disabled={false}
              placeholder={t("storeselect.search.placeholder")}
            />
            <StyledCheckbox
              testId="store-stock-show-instock"
              handleChange={handleFilterInStock}
              label={t("product.details.storestock.showall")}
            />
            <StoreSelectionWrapper>
              <StoreListComponent
                stores={
                  !onlyShowStoresWithStock
                    ? filteredSortedStores
                    : filteredStoresWithStock
                }
                handleStoreSelect={handleStoreSelect}
                selectedStoreId={selectedStoreId}
                selectedStoreDetails={selectedStoreDetails}
              />
            </StoreSelectionWrapper>
          </FormWrapper>
        </BodyContainer>
        <DialogFooter>
          <XxlStack
            direction={"row"}
            width={"100%"}
            gap={"12px"}
            justifyContent={"end"}
          >
            {!isMobile && (
              <DialogBtn
                size="large"
                variant="outlined"
                onClick={toggleDialog}
                data-testid="store-stock-action-btn"
              >
                {t("general.cancel")}
              </DialogBtn>
            )}
            <DialogBtn
              size="large"
              onClick={() => handleAddToCartClick(selectedStoreId)}
              disabled={isAddToCartBtnDisabled}
              data-testid="store-stock-action-btn"
            >
              {t("product.details.add.to.cart.label")}
            </DialogBtn>
          </XxlStack>
        </DialogFooter>
      </Dialog>
    </>
  );
};
