import styled from "@emotion/styled/macro";
import { MQ } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";

const { spaces, colors } = xxlTheme;

export const RewardsBannerSection = styled.section`
  width: 100%;
  background-color: ${colors.xxlGreen};
  margin-bottom: -120px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaces.large} 0;
  gap: ${spaces.large};

  ${MQ("tablet")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.smallRegular};

  ${MQ("tablet")} {
    flex-direction: row;
    align-items: center;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.mini};
`;

export const Heading = styled.h3`
  margin: 0;
`;

export const Description = styled.p`
  margin: 0;
`;
