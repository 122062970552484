import { QUANTITY_ONE } from "@/react-app/constants";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlButton } from "@/react-components/Common/XxlButton";
import { SALES_METHODS } from "@/react-hooks/useProductData/constants";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { parseNumber } from "@/react-utils/xxl-number";
import { Stack } from "@mui/material";
import { hasValue } from "@xxl/common-utils";
import { useInView } from "framer-motion";
import type { ChangeEvent } from "react";
import { useRef, useState } from "react";
import { AddToCartWarning } from "../AddToCartWarning/AddToCartWarning";
import { MAX_ITEM_QUANTITY } from "../constants";
import { getOnlineStockStatus, getSalesUnit } from "../helpers";
import {
  useShouldShowAtcSuccess,
  useShouldShowCncSuccess,
} from "../hooks/useSuccessStatus";
import { CART_LOADING_STATE, StockLevels } from "../types";
import { StickyAtcBtn } from "./StickyAtcBtn";
import { VariantInputs } from "./VariantSelect/VariantSelectButton";
import { VariantSelectDrawer } from "./VariantSelect/VariantSelectDrawer";
import { NumaricInput } from "./VariantSelect/styles";
import { VolumentalComponent } from "./VolumentalComponent";
import { Message, ProductFormButtonContainer } from "./styles";
import type { TProductForm } from "./types";

const ADD_TO_CART_BUTTON_ID = "product-add-to-cart";

export const ProductFormComponent = ({
  addToCartErrorMessage,
  cartLoadingState,
  configurations,
  hasPendingAddToCartAction,
  isVariantSelectOpen,
  onAtcClick,
  onVariantSelect,
  product,
  selectedVariantCode,
  toggleCncDialog,
  toggleConfigurationModal,
  toggleVariantSelectDrawer,
  volumentalMetaData,
}: TProductForm) => {
  const { t } = useTranslations();

  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const shouldShowAtcSuccess = useShouldShowAtcSuccess();
  const shouldShowCncSuccess = useShouldShowCncSuccess();

  const addToCartBtnRef = useRef(null);
  const isAddToCartBtnInView = useInView(addToCartBtnRef);
  const [quantity, setQuantity] = useState(QUANTITY_ONE);
  const isAmmunition = product.isAmmunition === true;
  const isMultipackBundle = [
    "MULTIPACK",
    "PRODUCT_MULTIPACK",
    "BUNDLE_MULTIPACK",
  ].includes(product.type);
  const isMultipack = isAmmunition || isMultipackBundle;
  const unit = getSalesUnit(product);

  const hasConfigurations = configurations.length > 0;
  const btnTxt = !hasConfigurations
    ? t("product.details.add.to.cart.label")
    : t("product.details.configure.product");

  const quantityToggleEnabled = Boolean(product.packageQuantity);
  const isVariantSelected = selectedVariantCode !== null;
  const shouldShowCncBtn = product.isExcludedFromClickAndCollect === false;
  const selectedVariant = product.variants.find(
    (variant) => variant.code === selectedVariantCode
  );

  const getIsInStockOnline = () => {
    if (selectedVariant !== undefined) {
      return (
        getOnlineStockStatus(selectedVariant.availability) !==
        StockLevels.OUT_OF_STOCK
      );
    }
    return product.isInStockOnline;
  };

  const shouldNotBePossibleToAddToCart = [
    !product.isSoldIndividually, // Product is not sold individually - is part of a bundle or is a service
    product.isUserSetting, // Product is a user setting
  ].some(Boolean);

  const isCncBtnEnabledArray = [
    isVariantSelected,
    cartLoadingState === CART_LOADING_STATE.CNC_LOADING,
  ];

  const isCncBtnEnabled =
    !shouldNotBePossibleToAddToCart && isCncBtnEnabledArray.some(Boolean);

  const isAtcDisabledArray = [
    cartLoadingState === CART_LOADING_STATE.ATC_LOADING,
    product.salesMethodCode === SALES_METHODS.DANGEROUS_GOODS,
    !product.isInStockOnline,
    !getIsInStockOnline(),
  ];
  const isAddToCartDisabled =
    shouldNotBePossibleToAddToCart || isAtcDisabledArray.some(Boolean);

  const isOneSize = product.variants.length === 1;
  if (isOneSize) {
    selectedVariantCode = product.variants[0].code;
    onVariantSelect({
      action: "select-variant",
      variantCode: selectedVariantCode,
    });
  }

  const isVariantDisabled = shouldNotBePossibleToAddToCart || isOneSize;

  const isDangerousGoods =
    SALES_METHODS.DANGEROUS_GOODS === product.salesMethodCode;
  const [{ code: ean }] = hasValue(product.variants)
    ? product.variants
    : [{ code: null }];

  /**
   * Handlers
   */

  const handleOnChangeQuantity = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setQuantity(parseNumber(target.value));

  const handleAtcClick = () => {
    if (hasConfigurations) {
      toggleConfigurationModal();
      return;
    }
    onAtcClick({ quantity });
  };

  const handleStickyAddToCartClick = () => {
    const addToCartElement = document.getElementById(ADD_TO_CART_BUTTON_ID);
    if (addToCartElement !== null) {
      addToCartElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    onAtcClick({ quantity });
  };

  const variantClickHandler = (variantCode: string) => {
    const action =
      hasPendingAddToCartAction && hasConfigurations
        ? "select-variant-and-open-configurations-modal"
        : hasPendingAddToCartAction
          ? "select-variant-and-add-to-cart"
          : ("select-variant" as const);
    onVariantSelect({
      action,
      variantCode,
      quantity,
    });
    toggleVariantSelectDrawer();
  };

  return (
    <>
      <Stack spacing={xxlTheme.spaces.micro}>
        {volumentalMetaData.isFitstationEnabled && hasValue(ean) && (
          <VolumentalComponent
            ean={ean}
            volumentalId={volumentalMetaData.volumentalId}
          />
        )}
        <VariantInputs
          onClick={toggleVariantSelectDrawer}
          open={isVariantSelectOpen}
          displayText={
            isMultipack
              ? t(`product.unit.amount.${unit}`)
              : selectedVariant?.label ?? t("product.details.select.size")
          }
          quantityToggleEnabled={quantityToggleEnabled}
          isOneSize={isOneSize}
          disabled={isVariantDisabled || isDangerousGoods}
        >
          <NumaricInput
            type="number"
            inputMode="numeric"
            required={true}
            min={QUANTITY_ONE}
            max={MAX_ITEM_QUANTITY}
            onBlur={() => setQuantity(quantity)}
            onChange={handleOnChangeQuantity}
            value={quantity}
          />
        </VariantInputs>
        <VariantSelectDrawer
          product={product}
          onClose={toggleVariantSelectDrawer}
          isOpen={isVariantSelectOpen}
          isLoading={cartLoadingState !== CART_LOADING_STATE.READY}
          heading={t("product.details.select.size")}
          onVariantClick={variantClickHandler}
          isVariantSelected={selectedVariantCode !== null}
        />
        <ProductFormButtonContainer shouldShowCnc={shouldShowCncBtn}>
          <XxlButton
            type="submit"
            id={ADD_TO_CART_BUTTON_ID}
            data-testid={ADD_TO_CART_BUTTON_ID}
            ref={addToCartBtnRef}
            disabled={isAddToCartDisabled}
            loading={cartLoadingState === CART_LOADING_STATE.ATC_LOADING}
            onClick={handleAtcClick}
            icon={shouldShowAtcSuccess === true ? "Check" : "Plus"}
          >
            {shouldShowAtcSuccess === true
              ? t("product.details.clickcollect.title.added")
              : btnTxt}
          </XxlButton>
          {shouldShowCncBtn && (
            <XxlButton
              data-testid="ccBtn"
              disabled={!isCncBtnEnabled}
              loading={cartLoadingState === CART_LOADING_STATE.CNC_LOADING}
              onClick={toggleCncDialog}
              variant={shouldShowCncSuccess ? "primary" : "secondary"}
              icon={shouldShowCncSuccess ? "Check" : undefined}
            >
              {shouldShowCncSuccess
                ? t("product.details.clickcollect.title.added")
                : t("product.details.clickcollect.title")}
            </XxlButton>
          )}
        </ProductFormButtonContainer>
        {product.salesMethodCode === SALES_METHODS.DANGEROUS_GOODS ? (
          <Message text={t("product.details.usp.dangerous.goods")} />
        ) : null}
        {addToCartErrorMessage !== null && (
          <AddToCartWarning addToCartError={addToCartErrorMessage} />
        )}
      </Stack>
      <StickyAtcBtn
        isStickyBtnVisible={!isLaptopSize && !isAddToCartBtnInView}
        disabled={isAddToCartDisabled}
        loading={cartLoadingState === CART_LOADING_STATE.ATC_LOADING}
        onClick={handleStickyAddToCartClick}
        salesPrice={product.price.selling.range.min.formatted}
        icon={shouldShowAtcSuccess === true ? "Check" : "Plus"}
      >
        {shouldShowAtcSuccess === true
          ? t("product.details.clickcollect.title.added")
          : btnTxt}
      </StickyAtcBtn>
    </>
  );
};
