import type * as OrderBETypes from "@xxl/types-master-accounts-service";
import type {
  AnonymousEncryptedPurchaseViewInput,
  AnonymousPurchaseInput,
  AnonymousQuery,
  BonusPointTransactionsInput,
  CreateReturnInput,
  CreateReturnMutation,
  DeliveryMethodForProductsQuery,
  GetAnonymousPurchaseQuery,
  GetBonusPointTransactionsQuery,
  GetReplacementInput,
  GetReplacementQuery,
  InitiateGiftCardPurchaseInput,
  InitiateGiftCardPurchaseMutation,
  ProductsDeliveryMethodsInput,
  PurchaseQuery,
  PurchaseQueryVariables,
  PurchasesQuery,
  PurchasesQueryVariables,
  ReturnReasonsForProductInput,
  ReturnReasonsForProductQuery,
  RewardsTransactionType,
} from "../../../generated/graphql-code-generator";
import type { NonRequiredUseNull } from "../../../global";
import { callGraphQL, type GraphQLResult } from "../../../graphql/graphqlApi";

export type RewardsTransaction = {
  amount: number;
  transactionDateTime: string;
  description: string;
  type: RewardsTransactionType;
  id: string;
  automationBonusAdjustmentReason?: string;
};

export type PurchaseAddress = NonRequiredUseNull<OrderBETypes.PurchaseAddress>;

export type PurchaseShippingStatus =
  NonRequiredUseNull<OrderBETypes.PurchaseShippingStatus>;

export type PurchasePackage = NonRequiredUseNull<OrderBETypes.PurchasePackage>;

export type PurchaseShipping = NonRequiredUseNull<
  Exclude<OrderBETypes.PurchaseShipping, "method">
> & { method: PurchaseShippingMethod | null };

export type PurchaseShippingMethod =
  NonRequiredUseNull<OrderBETypes.PurchaseShippingMethod> & {
    returnable: boolean;
  };

export type PurchaseProduct = NonRequiredUseNull<OrderBETypes.PurchaseProduct>;

export type PurchaseReturn = NonRequiredUseNull<OrderBETypes.PurchaseReturn>;

export type PurchaseLineBundleProduct =
  NonRequiredUseNull<OrderBETypes.PurchaseLine>;

export type PurchaseLine = NonRequiredUseNull<
  OrderBETypes.PurchaseLine & {
    shipmentStatuses: ShipmentStatusWithQuantity[] | null;
  }
>;

export type StatusPurchaseLine = PurchaseLine & {
  shipmentStatus: ShipmentStatusWithQuantity;
};
export type PurchaseClub = NonRequiredUseNull<{
  logoURL?: string;
  name?: string;
}>;

export type ShipmentStatusWithQuantity = {
  quantity: number;
  status: PurchaseShippingStatus;
} | null;

export type AccountPurchase = NonRequiredUseNull<
  Pick<
    OrderBETypes.AccountPurchase,
    | "SK"
    | "date"
    | "email"
    | "originalAmount"
    | "lastReturnDate"
    | "salesChannel"
    | "shippingAddress"
    | "billingAddress"
    | "lastModifiedTime"
  >
> & {
  storeName: string;
  shipping: PurchaseShipping;
  lines: BundleItemProps[];
  club?: Pick<PurchaseClub, "name">;
};

export type GetAnonymousEncryptedPurchaseView =
  AnonymousQuery["getAnonymousEncryptedPurchaseView"];

export interface PurchaseShippingSummary
  extends Pick<PurchaseShipping, "status" | "cost"> {
  packages: Pick<PurchasePackage, "status" | "trackingUrl">[] | null;
  method: Pick<PurchaseShippingMethod, "returnable" | "id"> | null;
}

export interface PurchaseLineSummary
  extends Pick<PurchaseLine, "returnableQuantity" | "orderedQuantity"> {
  returns: Pick<PurchaseReturn, "status" | "quantity">[] | null;
}

export interface AccountPurchaseSummary
  extends Pick<
    AccountPurchase,
    | "SK"
    | "date"
    | "storeName"
    | "originalAmount"
    | "lastReturnDate"
    | "salesChannel"
  > {
  shipping: PurchaseShippingSummary;
  lines: PurchaseLineSummary[];
  club?: PurchaseClub;
}

export interface AccountPurchaseResponse {
  getPurchase: AccountPurchase;
}

export interface AccountPurchasesResponse {
  getPurchases: AccountPurchases;
}

export type GiftCardCheckoutDetails = Pick<
  InitiateGiftCardPurchaseMutation,
  "initiateGiftCardPurchase"
>["initiateGiftCardPurchase"];

export type InitiateGiftCardPurchaseResponse = InitiateGiftCardPurchaseMutation;

export interface AccountPurchases {
  nextToken: string | null;
  purchases: AccountPurchaseSummary[];
}

export interface BundleItemProps extends PurchaseLine {
  products?: PurchaseLine[];
}

export type GetBonusPointTransactions =
  NonNullable<GetBonusPointTransactionsQuery>["bonusPointTransactions"];
export type BonusPointTransaction =
  NonNullable<GetBonusPointTransactions>["transactions"][0];

export type BonusHistoryPagination = Pick<
  NonNullable<GetBonusPointTransactions>,
  "count" | "offset" | "totalCount"
>;

/**
 * nb. that any changes to this query needs to be reflected in the picked fields of AccountPurchases and children,
 * updates to @xxl/types-master-accounts-service might need updates to this query
 */
const purchaseQueryFields = /* GraphQL */ `
  fragment purchaseQueryFields on Purchase {
    SK
    date
    email
    lastModifiedTime
    lastReturnDate
    billingAddress {
      city
      postalCode
      street
    }
    originalAmount
    salesChannel
    storeName
    shippingAddress {
      city
      postalCode
      street
    }
    shipping {
      status
      selectedPickupPoint
      pickupCode
      method {
        id
        name
      }
      cost
      packages {
        deliveredTimestamp
        lastModifiedTime
        location
        packageNumber
        packedTimestamp
        pickupReadyTimestamp
        receivedTimestamp
        sentTimestamp
        status
        trackingUrl
      }
    }
    lines {
      bundleNumber
      deliveredQuantity
      invoicedQuantity
      orderedQuantity
      product {
        articleNumber
        url
        size
        serviceProduct
        name
        image
        eanCode
        brandName
        baseColor
      }
      returnableQuantity
      returns {
        date
        packageNumber
        quantity
        reason
        returnLineId
        status
      }
      salesOrder
      totalAmount
      unitPrice
    }
  }
`;

export const getPurchase = ({
  graphQLEndpointUrl,
  teamSales,
  orderId,
}: {
  graphQLEndpointUrl: string;
  orderId: string;
  teamSales: boolean;
}): Promise<GraphQLResult<PurchaseQuery>> => {
  const query = /* GraphQL */ `
    query Purchase($orderId: String, $teamSales: Boolean!) {
      getPurchase(input: { code: $orderId }) {
        ...purchaseQueryFields
        club @include(if: $teamSales) {
          name
        }
      }
    }

    ${purchaseQueryFields}
  `;

  const variables: PurchaseQueryVariables = { orderId, teamSales };

  return callGraphQL({ query, variables }, graphQLEndpointUrl);
};

type GetPurchasesProps = {
  graphQLEndpointUrl: string;
  teamSales: boolean;
  nextToken?: string;
};

/**
 * nb. that any changes to this query needs to be reflected in the picked fields of AccountPurchaseSummary and children,
 * updates to @xxl/types-master-accounts-service might need updates to this query
 */
const purchasesQueryFields = /* GraphQL */ `
  fragment purchasesQueryFields on Purchase {
    SK
    date
    lastReturnDate
    originalAmount
    salesChannel
    storeName
    shipping {
      status
      packages {
        status
        trackingUrl
      }
    }
    lines {
      returnableQuantity
      orderedQuantity
      returns {
        status
        quantity
      }
    }
  }
`;

export const getPurchases = ({
  graphQLEndpointUrl,
  teamSales,
  nextToken,
}: GetPurchasesProps): Promise<GraphQLResult<PurchasesQuery>> => {
  const query = /* GraphQL */ `
    query Purchases($nextToken: String, $teamSales: Boolean!) {
      getPurchases(
        input: {
          pagination: { nextToken: $nextToken, scanIndexForward: false }
        }
      ) {
        nextToken
        purchases {
          ...purchasesQueryFields
          club @include(if: $teamSales) {
            logoURL
            name
          }
        }
      }
    }

    ${purchasesQueryFields}
  `;

  const variables: PurchasesQueryVariables = {
    nextToken: nextToken ?? null,
    teamSales,
  };

  return callGraphQL({ query, variables }, graphQLEndpointUrl);
};

export const initiateGiftCardPurchaseMutation = /* GraphQL */ `
  mutation InitiateGiftCardPurchase($input: InitiateGiftCardPurchaseInput!) {
    initiateGiftCardPurchase(input: $input) {
      cartId
      htmlSnippet
      orderId
      customerType
      customerTypeOptions
      paymentProvider
    }
  }
`;

export const initiateGiftCardPurchase = async (
  input: InitiateGiftCardPurchaseInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<GraphQLResult<InitiateGiftCardPurchaseResponse>> => {
  return callGraphQL(
    {
      query: initiateGiftCardPurchaseMutation,
      variables: { input },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );
};

const getAnonymousEncryptedPurchaseViewQuery = /* GraphQL */ `
  query Anonymous($encryptedOrderId: String!, $market: Market!) {
    getAnonymousEncryptedPurchaseView(
      input: { encryptedOrderId: $encryptedOrderId, market: $market }
    ) {
      SK
      lines {
        bundleNumber
        deliveredQuantity
        invoicedQuantity
        orderedQuantity
        product {
          articleNumber
          url
          size
          serviceProduct
          name
          image
          eanCode
          brandName
          baseColor
        }
        returnableQuantity
        shipmentStatuses {
          quantity
          status
        }
        salesOrder
        totalAmount
        unitPrice
      }
    }
  }
`;

export const getAnonymousPurchase = async (
  input: AnonymousEncryptedPurchaseViewInput,
  graphQLEndpointUrl: string,
  apiKey: string
): Promise<GraphQLResult<AnonymousQuery>> => {
  return callGraphQL(
    {
      query: getAnonymousEncryptedPurchaseViewQuery,
      variables: input,
      forceAnonymous: true,
    },
    graphQLEndpointUrl,
    apiKey
  );
};

const bonusPointsTransactionsQuery = /* GraphQL */ `
  query GetBonusPointTransactions($input: BonusPointTransactionsInput) {
    bonusPointTransactions(input: $input) {
      count
      offset
      totalCount
      transactions {
        amount
        automationBonusAdjustmentReason
        description
        id
        transactionDateTime
        type
        validFromDateTime
      }
    }
  }
`;

export const getBonusPointsTransactions = async (
  input: BonusPointTransactionsInput,
  graphQLEndpointUrl: string
): Promise<GraphQLResult<GetBonusPointTransactionsQuery>> =>
  callGraphQL(
    {
      query: bonusPointsTransactionsQuery,
      variables: { input },
    },
    graphQLEndpointUrl
  );

const returnReasonsForProductQuery = /* GraphQL */ `
  query ReturnReasonsForProduct($input: ReturnReasonsForProductInput!) {
    returnReasonsForProduct(input: $input) {
      reasons {
        code
        description
        placement
        type
      }
    }
  }
`;

export const getReturnReasonsForProducts = async (
  input: ReturnReasonsForProductInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<GraphQLResult<ReturnReasonsForProductQuery>> =>
  callGraphQL(
    {
      query: returnReasonsForProductQuery,
      variables: { input },
      forceAnonymous: true,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

const deliveryMethodForProductsQuery = /* GraphQL */ `
  query DeliveryMethodForProducts($input: ProductsDeliveryMethodsInput!) {
    fetchDeliveryMethodForProducts(input: $input) {
      onOrderItem
      expressDeliveryAvailable
      deliveryMethods {
        returnable
      }
    }
  }
`;

export const getDeliveryMethodForProducts = async (
  input: ProductsDeliveryMethodsInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<GraphQLResult<DeliveryMethodForProductsQuery>> =>
  callGraphQL(
    {
      query: deliveryMethodForProductsQuery,
      variables: { input },
      forceAnonymous: true,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

const getReplacement = /* GraphQL */ `
  query GetReplacement($input: GetReplacementInput!) {
    getReplacement(input: $input) {
      cartId
      htmlSnippet
      customerType
      customerTypeOptions
      paymentProvider
    }
  }
`;

export const getReplacementCheckoutSnippet = async (
  input: GetReplacementInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<GraphQLResult<GetReplacementQuery>> =>
  callGraphQL<GetReplacementQuery>(
    {
      query: getReplacement,
      variables: { input },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

export const createReturnMutation = /* GraphQL */ `
  mutation CreateReturn($input: CreateReturnInput!) {
    createReturn(input: $input) {
      paperless
      carrierName
      shipmentLines {
        lineCSID
        pkgs {
          pkgNo
        }
      }
      labels {
        pkgNo
        content
      }
    }
  }
`;

export const createReturn = async (
  input: CreateReturnInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<GraphQLResult<CreateReturnMutation>> =>
  callGraphQL<CreateReturnMutation>(
    {
      query: createReturnMutation,
      variables: { input },
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );

export const getAnonymousPurchaseQuery = /* GraphQL */ `
  query GetAnonymousPurchase($input: AnonymousPurchaseInput!) {
    anonymousPurchase(input: $input) {
      SK
      lastReturnDate
      lines {
        bundleNumber
        deliveredQuantity
        invoicedQuantity
        orderedQuantity
        product {
          articleNumber
          url
          size
          serviceProduct
          name
          image
          eanCode
          brandName
          baseColor
        }
        returnableQuantity
        shipmentStatuses {
          quantity
          status
        }
        salesOrder
        totalAmount
        unitPrice
      }
      salesChannel
      shipping {
        status
      }
    }
  }
`;

export const getAnonymousPurchaseForReturn = async (
  input: AnonymousPurchaseInput,
  graphQLEndpointUrl: string,
  graphQLApiKey: string
): Promise<GraphQLResult<GetAnonymousPurchaseQuery>> =>
  callGraphQL<GetAnonymousPurchaseQuery>(
    {
      query: getAnonymousPurchaseQuery,
      variables: { input },
      forceAnonymous: true,
    },
    graphQLEndpointUrl,
    graphQLApiKey
  );
